import "../../../App.css"
import AlbercaRogerhumbnails from "../../../Img/Proyectos/ProyectosCasasDescanso/AlbercaRoger/65d4d49f005dcbce930d11b2_01.jpg"
import AlbercaPacificoThumbnails from "../../../Img/Proyectos/ProyectosCasasDescanso/AlbercaPacifico/61ce10e67d74a752e28aa81d_AC-7.1.jpg"
import Casa9Thumbnails from "../../../Img/Proyectos/ProyectosCasasDescanso/Casa9/61ce12e85d1eb263e6835024_C9.Chacala.3.jpg"
import OsoGrandeThumbnails from "../../../Img/Proyectos/ProyectosCasasDescanso/CasaOsoGrande/61ce6529b2978c576b5cba31_1.jpg"
import VillaBelHaThumbnails from "../../../Img/Proyectos/ProyectosCasasDescanso/VillaBelHa/61ce680240038a9461264e2a_2.jpg"
import CasaAlbatrosThumbnails from "../../../Img/Proyectos/ProyectosCasasDescanso/CasaAlbatros/Vista 4.jpg"
import PescadoresThumbnails from "../../../Img/Proyectos/ProyectosDesarrollo/Pescadores/65d5078bcddc61efbc399cd8_Conjunto 03.jpg"
import SayulitaRevolucionThumbnails from "../../../Img/Proyectos/ProyectosDesarrollo/SayulitaRevolucion/65d5055b87f3383d44e686b6_CONJ 01.jpg"
import CasaTuCasaThumbnails from "../../../Img/Proyectos/ProyectosDesarrollo/CasaTuCasa/651c47e075cc834ef59b19fa_ALFONSO NUÑEZ- EXTERIOR LATERAL POSTERIOR-05.jpg"
import { Helmet } from "react-helmet"

function ProyectosCasasDescandoHome() {
    return (
        <>

            <Helmet>
                <title>Playa y Montaña</title>
            </Helmet>

            <div className="container col-12 py-5">
                <div className="d-flex">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/proyectos">Proyectos</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Playa y Montaña</li>
                        </ol>
                    </nav>
                </div>
                <h1 className="py-2 m-0 p-0">Playa y Montaña</h1>
            </div>

            <div class="container justify-content-center align-items-center">
                <div class="row gx-4 gy-4">

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-alberca-pacifico">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={AlbercaPacificoThumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Alberca Pácifico</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyectos-alberca-roger">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={AlbercaRogerhumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Alberca Roger</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-casa9">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={Casa9Thumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Casa 9</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-casa-albatros">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={CasaAlbatrosThumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Casa Albatros</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-casa-oso-grande-chacala">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={OsoGrandeThumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Casa Oso Grande</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-pescadores">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={PescadoresThumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Pescadores</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-sayulita-revolucion">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={SayulitaRevolucionThumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Sayulita Revolución</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-tapalpa-c4">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={CasaTuCasaThumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Tapalpa C4</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-villa-bel-ha">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={VillaBelHaThumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Villa Bel Ha</h5>
                                </div>
                            </div>
                        </a>
                    </div>



                    {/*
                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/casa-sin-fin-chacala">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={ChacalaThumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Casa sin Fin, Chacala</h5>
                                </div>
                            </div>
                        </a>
                    </div>
                    
                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-casa-la-herradura-chacala">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={HerraduraThumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Casa La Herradura, Chacala</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-casa-e">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={CasaEThumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Casa E, Chacala</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-casa-sueno">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={CasaSuenoThumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Casa Sueño, Manzanillo</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-villa-bel-ha-playa-del-carmen">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={VillaBelHaThumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Villa Bel Ha, Playa del Cármen</h5>
                                </div>
                            </div>
                        </a>
                    </div>
                */}
                </div>
            </div>

        </>
    );
}

export default ProyectosCasasDescandoHome;
