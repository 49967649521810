import "../../../App.css"
import ResidencialPOThumbnails from "../../../Img/Proyectos/ProyectosResidenciales/ResidencialPO/61ce0b3aec15b57385e8bfcc_Casa Plasencia 4p.jpg"
import CasaManantialThumbnails from "../../../Img/Proyectos/ProyectosResidenciales/CasaManantial/61ce0c0d03a8ee20bfa803ed_102_0317.jpg"
import CasaVGThumbnails from "../../../Img/Proyectos/ProyectosResidenciales/CasaVG/61ce0dfe7dc00c5b66501342_VALDEZ 3.jpg"
import Casa8AThumbnails from "../../../Img/Proyectos/ProyectosResidenciales/Casa8A/61cd3878ed503d32dc16afc4_Casa.C8.1.jpg"
import CasaMontanasThumbnails from "../../../Img/Proyectos/ProyectosResidenciales/CasaMontanas/12314400_788300041292733_3548315991914547419_o.jpg"
import CasaFRVThumbnails from "../../../Img/Proyectos/ProyectosResidenciales/CasaFRV/61ce0488fb5d2689d90f0acf_1.jpg"
import CasaPradoThumbnails from "../../../Img/Proyectos/ProyectosResidenciales/CasaPrado/61ce0546f0cbc7beacf3d093_425133_140459179410159_1985967975_n.jpg"
import CasaPalmas14Thumbnails from "../../../Img/Proyectos/ProyectosResidenciales/Palmas14/61ce062d8d36f1ba32b8fa15_1.jpg"
import CasaVP5Thumbnails from "../../../Img/Proyectos/ProyectosResidenciales/CasaVP5/61ce084e7957d30c27f33d14_Casa5.11.jpg"
import CasaRCThumbnails from "../../../Img/Proyectos/ProyectosResidenciales/CasaRC/10848720_719943581461713_3923465122721615354_o(1).jpg"
import ProyectoAlcocerThumbnails from "../../../Img/Proyectos/ProyectosResidenciales/ProyectoAlcocer/D5_Image_20230503_133120.png"
import ProyectoDepartamentosAnguloThumbnails from "../../../Img/Proyectos/ProyectosResidenciales/ProyectoDepartamentosAngulo/Vista 01.jpg"
import ProyectoBuenosAiresThumbnails from "../../../Img/Proyectos/ProyectosResidenciales/ProyectoBuenosAires/Vista 05A.jpg"
import ProyectoCubileteThumbnails from "../../../Img/Proyectos/ProyectosResidenciales/ProyectoCubilete/D5_Image_20240405_125155.png"
import ProyectoHidalgoThumbnails from "../../../Img/Proyectos/ProyectosResidenciales/ProyectoHidalgo/12.jpg"
import ProyectoLaRiojaThumbnails from "../../../Img/Proyectos/ProyectosResidenciales/ProyectoLaRioja/Vista 03.jpg"
import ProyectoSanLuisThumbnails from "../../../Img/Proyectos/ProyectosResidenciales/ProyectoSanLuis/Vista 07.jpg"
import ProyectoPlenitudThumbnails from "../../../Img/Proyectos/ProyectosResidenciales/ProyectoPlenitud/Vista 2.jpg"
import ProyectoRenacimientoMontereyThumbnails from "../../../Img/Proyectos/ProyectosResidenciales/ProyectoRanchetteMorelia/6.jpg"
import ProyectoCasaJuricaThumbnails from "../../../Img/Proyectos/ProyectosResidenciales/ProyectoCasaJurica/11.jpg"
import RanchetteMoreliaThumbnails from "../../../Img/Proyectos/ProyectosResidenciales/ProyectoRanchetteMorelia/6.jpg"
import ProyectoSanLuisTorreThumbnails from "../../../Img/Proyectos/ProyectosResidenciales/SanLuisTorre/VISTA 13.jpg"
import ProyectoRenacimientoMonterreyThumbnails from "../../../Img/Proyectos/ProyectosResidenciales/ProyectoRenacimientoMonterey/07.effectsResult.jpg"

import { Helmet } from "react-helmet"

function ProyectosResidencialHome() {
    return (
        <>

            <Helmet>
                <title>Residencial</title>
            </Helmet>

            <div className="container col-12 py-5">
                <div className="d-flex">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/proyectos">Proyectos </a></li>
                            <li class="breadcrumb-item active" aria-current="page">Residencial</li>
                        </ol>
                    </nav>
                </div>
                <h1 className="mt-0">Residencial</h1>
            </div>

            <div class="container justify-content-center align-items-center">
                <div class="row gx-4 gy-4">

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-casa-8a">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={Casa8AThumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Casa 8A</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-casa-de-las-montanas">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={CasaMontanasThumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Casa de las Montañas</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-palmas-14">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={CasaPalmas14Thumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Palmas 14</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-casa-rc">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={CasaRCThumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Casa RC</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-alcocer">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={ProyectoAlcocerThumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Proyecto Alcocer</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-departamentos-angulo">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={ProyectoDepartamentosAnguloThumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Proyecto Departamentos Angulo</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-buenos-aires">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={ProyectoBuenosAiresThumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Proyecto Buenos Aires</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-departamentos-cubilete">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={ProyectoCubileteThumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Proyecto Departamentos Cubilete</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-departamentos-hidalgo">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={ProyectoHidalgoThumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Proyecto Departamentos Hidalgo</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-casa-jurica">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={ProyectoCasaJuricaThumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Proyecto Casa Jurica</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-la-rioja">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={ProyectoLaRiojaThumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Proyecto La Rioja</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-plan-de-san-luis">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={ProyectoSanLuisThumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Proyecto Plan de San Luis</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-departamentos-plenitud">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={ProyectoPlenitudThumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Proyecto Departamentos Plenitud</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    
                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-ranchette-morelia">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={RanchetteMoreliaThumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Proyecto Ranchette Morelia</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-renacimiento-monterrey">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={ProyectoRenacimientoMonterreyThumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Proyecto Renacimiento Monterrey</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-san-luis-torre">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={ProyectoSanLuisTorreThumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Proyecto San Luis Torre</h5>
                                </div>
                            </div>
                        </a>
                    </div>


                    {/*

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-residencial-po">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={ResidencialPOThumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Residencial PO</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-casa-frv">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={CasaFRVThumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Casa FRV</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-casa-manantial">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={CasaManantialThumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Casa Manantial</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-casa-gv">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={CasaVGThumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Casa GV</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-casa-prado">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={CasaPradoThumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Casa Prado</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-vp5">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={CasaVP5Thumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Casa VP 5</h5>
                                </div>
                            </div>
                        </a>
                    </div>
*/}

                </div>
            </div>

        </>
    );
}

export default ProyectosResidencialHome;
