import "../../App.css";
import { Helmet } from 'react-helmet';
import PictureAcabadosPrincipal from "../../Img/Blogs/AcabadosDeLujo/61ce9f47d0a0a57a99ebc7dd_blog_5.webp"
import PictureAcabados1 from "../../Img/Blogs/AcabadosDeLujo/61ce9e2fb9b6985bac8287f3_Web-1920-–-7.webp"
import PictureAcabados2 from "../../Img/Blogs/AcabadosDeLujo/61ce9f47d0a0a57a99ebc7dd_blog_12.webp"
import PictureAcabados3 from "../../Img/Blogs/AcabadosDeLujo/61ce9f47d0a0a57a99ebc7dd_blog_9.webp"


function AcabadosDeLujo() {




    return (
        <>
            <Helmet>
                <title>Blog - ¡Con acabados de lujo!</title>
                <meta property="og:title" content="Guía para contratar servicios arquitectónicos" />
                <meta property="og:description" content="Aprende cómo contratar los mejores servicios arquitectónicos con nuestra guía completa. Descubre qué preguntar, cómo seleccionar un arquitecto y más. ¡Lee ahora!" />
                <meta property="og:url" content="https://demo-alfredo.godspeedcheckout.com" />
                <meta property="og:image" content="../../../public/Blogs/Guia-Arquitecto/arquitecto-sonriente-casco_23-2147702525.jpg" />
                <meta property="og:type" content="article" />
            </Helmet>

            <div className="container col-12 col-sm-12 col-md-12 col-lg-10 col-xl-7 col-xxl-8 py-4">
                <div className="col-12">
                    <div className="d-flex">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/blog">Blog</a></li>
                                <li class="breadcrumb-item active" aria-current="page">¡Con acabados de lujo!</li>
                            </ol>
                        </nav>
                    </div>
                    <h1 className="py-2 m-0 p-0">¡Con acabados de lujo!</h1>
                    <h6 class="fw-light">03 de febrero, 2021</h6>
                </div>
            </div>

            <div className="container col-12 col-sm-12 col-md-12 col-lg-10 col-xl-7 col-xxl-8 mx-auto">
                <img src={PictureAcabadosPrincipal} className="w-100 my-4" alt="Imagen Blog" />
            </div>

            <div className="container col-12 col-sm-12 col-md-12 col-lg-10 col-xl-7 col-xxl-5 mx-auto mt-4">
                <h5 className="lh-base fw-light">
                    En los desarrollos habitacionales en venta o en el hotel de playa que deseamos visitar es muy común encontrarnos con estas palabras: ¡Con acabados de Lujo…! Supongo que el tema del “lujo” es un tanto aspiracional: nos llama la atención poder disfrutar, poseer o presumir “ese tipo” de materiales…
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    <i> Pero, ¿Qué es el lujo?; copio del diccionario:</i>
                </h5>

                <h5 className="lh-base fw-light mt-4 fw-semibold">
                    1.- Exhibición o manifestación de riqueza.
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    “El lujo es un concepto tan subjetivo que en él cabe todo, desde el caviar ‘beluga’ al placer de no tener que hacer nada.”
                </h5>

                <h5 className="lh-base fw-light mt-4 fw-semibold">
                    2.- Cosa o conjunto de cosas prescindibles que suponen gran gasto de dinero o de tiempo.
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    “Pretendía pasar ocho meses navegando por el mítico río estudiando monumentos antiguos y en un barco con todo tipo de lujos.”
                </h5>

                <h5 className="lh-base fw-light mt-4 fw-semibold">
                    3.- Es algo a lo que la mayoría de las personas no pueden acceder.
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    Ya sea porque es demasiado caro, porque es algo que se da pocas veces en la vida, o porque son oportunidades especiales y escasas que unos pocos pueden aprovechar.
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    Los materiales “lujosos” no son malos ni incorrectos en una obra; pueden estar perfectamente justificados por alguna de sus características como el color, dimensión, durabilidad, o simplemente por el gusto de tenerlo, pero muchas veces los “acabados de lujo” son para recordarnos -o recordarles a los demás- que podemos pagar cosas costosas  o que hemos sido afortunados de disfrutar de algo que pocos lo podrán hacer…
                </h5>

                <h5 className="lh-base mt-4 fw-light">
                    Tengo muchos años de experiencia como arquitecto y puedo decir con toda franqueza que nunca me he preocupado o tenido la intención de sugerir o especificar “acabados de lujo” para mis proyectos. Mis clientes jamás me los han solicitado para sus proyectos, ya sean casas, oficinas, hoteles, casas sencillas y humildes o grandes residencias, hoteles de “categoría especial”…: nunca ha sido una prioridad en las intenciones de mis clientes o en las mías…
                </h5>

                <img src={PictureAcabados1} className="img-fluid mt-4" alt="Imagen Blog" />

                <h5 className="lh-base fw-light mt-4">
                    Desde luego que he tenido oportunidad de conocer y disfrutar de este tipo de acabados: He visto accesorios de baño con acabados de oro, pisos elaborados placas de mármol traído de lugares remotos (me pregunto si las canteras de aquellos lugares son “de lujo”), pero no necesariamente ofrecen ventajas importantes ante los acabados… ¿Cómo los llamaremos?: ¿Sencillos, convencionales, económicos, adecuados…?
                </h5>


                <h5 className="lh-base fw-light mt-4">
                    Estoy cierto que hay materiales exóticos y costosos que son durables y bellos, pero también es verdad de que hay materiales con las mismas características a una fracción de su valor. En mi experiencia los clientes no son los que generalmente buscan lujos, ya que muchas veces no los conocen ni está en sus mentes pagar por ellos; pienso que los arquitectos son los que realmente los desean…(¿¡!?)
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    Resulta que, salvo excepciones contadas y justificadas, los acabados de lujo parecieran ser  una necesidad de los propios arquitectos que los necesitan para presumir su obra “espectacular”, convencer a sus clientes de lo “único e invaluable” de su proyecto o -y esto sí podría ser un tanto escandaloso- para elevar el precio de la construcción y generar más honorarios…
                </h5>

                <img src={PictureAcabados2} className="img-fluid mt-4" alt="Imagen Blog" />

                <h5 className="lh-base fw-light mt-4">
                    Desde luego que, en la búsqueda de materiales adecuados para nuestro proyecto, podremos encontrar alguno que es adecuado por su belleza, durabilidad o proporción que justifiquen su elección, pero es verdad que en ocasiones, el solo saber que es costoso puede ser una tentación utilizarlo.
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    Muchas veces estos materiales requieren un cuidado o mantenimiento que con el tiempo nos podamos arrepentir de su elección. Conozco el caso de una obra de un arquitecto (…)  que decidió colocar en la fachada de una residencia un muro recubierto de madera un tanto exótica, pero el cliente no fue consciente ni concientizado de que al año requeriría mantenimiento…; y al año siguiente también mantenimiento…; y de nuevo el siguiente año… Terminaron por retirar la madera y colocar un material pétreo, adecuado estéticamente y del cual se olvidaron que existía… La madera en la fachada sirvió para la foto en la revista y poco más…
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    Lujo es tener la oportunidad y la asesoría necesaria para elegir los materiales adecuados, bellos, duraderos y de poco mantenimiento a precios razonables…
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    El verdadero lujo es disfrutar de nuestro hogar sabiendo que todo es adecuado, sin necesidad de lujos innecesarios…
                </h5>

                <img src={PictureAcabados3} className="img-fluid mt-4" alt="Imagen Blog" />

                <h6 className="fw-light mt-4">
                    — Arq. Alfonso Núñez.
                </h6>

            </div>
        </>
    );
}

export default AcabadosDeLujo;
