import React, { useState } from "react";
import { Helmet } from "react-helmet";
import FsLightbox from "fslightbox-react";
import "../../../App.css"
import image2 from "../../../Img/Proyectos/ProyectosCasasDescanso/VillaBelHa/61ce67f2c827901baa0a2b55_1.jpg"
import image1 from "../../../Img/Proyectos/ProyectosCasasDescanso/VillaBelHa/61ce680240038a9461264e2a_2.jpg"
import image3 from "../../../Img/Proyectos/ProyectosCasasDescanso/VillaBelHa/61ce6827bda4b085fec9bffd_3.jpg"
import image4 from "../../../Img/Proyectos/ProyectosCasasDescanso/VillaBelHa/61ce6826caa2663bebbd2154_4.jpg"
import image5 from "../../../Img/Proyectos/ProyectosCasasDescanso/VillaBelHa/61ce682665c416aa2dc77b9d_5.jpg"
import image6 from "../../../Img/Proyectos/ProyectosCasasDescanso/VillaBelHa/61ce68257d74a74f748d10e2_6.jpg"
import image7 from "../../../Img/Proyectos/ProyectosCasasDescanso/VillaBelHa/61ce6825f9e0ca3aaa8b722d_7.jpg"
import image8 from "../../../Img/Proyectos/ProyectosCasasDescanso/VillaBelHa/61ce68257d74a77ce28d10e1_8.jpg"
import image9 from "../../../Img/Proyectos/ProyectosCasasDescanso/VillaBelHa/61ce68255d1eb235f485d3e6_9.jpg"


function ProyectoVillaBelHa() {

    const [toggler, setToggler] = useState(false);

    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });

    function openLightboxOnSlide(number) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number
        });
    }


    return (
        <>

            <Helmet>
                <title>Proyecto Casas de Descanso - Villa Bel Ha, Playa del Cármen</title>
            </Helmet>

            <FsLightbox
                toggler={lightboxController.toggler}

                sources={[
                    image1, image2, image3, image4, image5, image6, image7, image8, image9
                ]}

                slide={lightboxController.slide}
            />

            <div className="container col-12 col-sm-12 col-md-12 col-lg-10 col-xl-8 col-xxl-5 mx-auto pb-4">
                <h1 className="py-4 text-center m-0 p-0">Villa Bel Ha, Playa del Cármen</h1>
            </div>

            <div className="container position-relative">
                <img onClick={() => openLightboxOnSlide(1)} type="button" src={image1} className="w-100" alt="..." height={650} />
            </div>


            <div className="container">
                <div className="row gy-4 mt-1">

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(2)}
                            src={image2}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(3)}
                            src={image3}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(4)}
                            src={image4}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(5)}
                            src={image5}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>


                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(6)}
                            src={image6}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>


                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(7)}
                            src={image7}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(8)}
                            src={image8}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(9)}
                            src={image9}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                </div>
            </div>
        </>
    );
}

export default ProyectoVillaBelHa;
