import "../App.css"
import { Helmet } from "react-helmet"

function NotFound() {
    return (
        <>
            <Helmet>
                <title>Nosotros - Alfonso Nuñez Arquitectos</title>
            </Helmet>

            <section className="container">
                <div className="row min-vh-100 align-items-center">
                    <div>
                        <h1 className="display-1">404</h1>
                        <h4 className="fw-light">La página que estás buscando no existe.</h4>
                        <h6 className="mt-5">
                            <a href="/" className="border-bottom">
                            Ir a la página principal
                            </a>
                        </h6>
                    </div>
                </div>
            </section>



        </>
    );
}

export default NotFound;
