import React, { useState } from "react";
import { Helmet } from "react-helmet";
import FsLightbox from "fslightbox-react";
import "../../../App.css"
import image1 from "../../../Img/Proyectos/ProyectosDesarrollo/AlcocerSMA/65d4fc1274d2bcdff286d11c_a.png"
import image5 from "../../../Img/Proyectos/ProyectosDesarrollo/AlcocerSMA/65d4fc11215d5e3121a7c3e8_D5_Image_20230504_130524.png"
import image3 from "../../../Img/Proyectos/ProyectosDesarrollo/AlcocerSMA/65d4fc2f93ca256a0ab3b222_D5_Image_20230504_125947.png"
import image4 from "../../../Img/Proyectos/ProyectosDesarrollo/AlcocerSMA/65d4fc42d37c08516cc2d80a_D5_Image_20230504_130239.png"
import image2 from "../../../Img/Proyectos/ProyectosDesarrollo/AlcocerSMA/65d4fd50a4b18c8531035a0d_c.png"
import image6 from "../../../Img/Proyectos/ProyectosDesarrollo/AlcocerSMA/65d4fc342e9b37799b10210e_D5_Image_20230504_132233.png"

function ProyectoAlcocerSMA() {

    const [toggler, setToggler] = useState(false);

    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });

    function openLightboxOnSlide(number) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number
        });
    }


    return (
        <>

            <Helmet>
                <title>Proyectos en Desarrollo - Alcocer SMA</title>
            </Helmet>

            <FsLightbox
                toggler={lightboxController.toggler}

                sources={[
                    image1, image2, image3, image4, image5, image6
                ]}

                slide={lightboxController.slide}
            />

            <div className="container col-12 col-sm-12 col-md-12 col-lg-10 col-xl-8 col-xxl-5 mx-auto pb-4">
                <h1 className="py-4 text-center m-0 p-0">Alcocer SMA</h1>
            </div>

            <div className="container position-relative">
                <img onClick={() => openLightboxOnSlide(1)} type="button" src={image1} className="w-100" alt="..." height={650} />
            </div>


            <div className="container">
                <div className="row gy-4 mt-1">

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(2)}
                            src={image2}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(3)}
                            src={image3}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(4)}
                            src={image4}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(5)}
                            src={image5}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>
                
                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(6)}
                            src={image6}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                </div>
            </div>
        </>
    );
}

export default ProyectoAlcocerSMA;
