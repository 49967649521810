import "../../App.css";
import { Helmet } from 'react-helmet';
import PictureObjetivoArquitecturaPrincipal from "../../Img/Blogs/ObjetivoArquitectura/61ce9f47d0a0a57a99ebc7dd_blog_9.webp"
import PictureObjetivoArquitectura2 from "../../Img/Blogs/ObjetivoArquitectura/61ce84dbf9e0ca69a08c2a08_blog_2.jpg"

function ObjetivoArquitectura() {




    return (
        <>
            <Helmet>
                <title>Blog - El objetivo no es la arquitectura, sino lo que permitimos que suceda en ella</title>
                <meta property="og:title" content="Guía para contratar servicios arquitectónicos" />
                <meta property="og:description" content="Aprende cómo contratar los mejores servicios arquitectónicos con nuestra guía completa. Descubre qué preguntar, cómo seleccionar un arquitecto y más. ¡Lee ahora!" />
                <meta property="og:url" content="https://demo-alfredo.godspeedcheckout.com" />
                <meta property="og:image" content="../../../public/Blogs/Guia-Arquitecto/arquitecto-sonriente-casco_23-2147702525.jpg" />
                <meta property="og:type" content="article" />
            </Helmet>

            <div className="container col-12 col-sm-12 col-md-12 col-lg-10 col-xl-7 col-xxl-8 py-4">
                <div className="col-12">
                    <div className="d-flex">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/blog">Blog</a></li>
                                <li class="breadcrumb-item active" aria-current="page">El objetivo no es la arquitectura, sino lo que permitimos que suceda en ella…</li>
                            </ol>
                        </nav>
                    </div>
                    <h1 className="py-2 m-0 p-0">El objetivo no es la arquitectura, sino lo que permitimos que suceda en ella…</h1>
                    <h6 class="fw-light mt-3">1 de octubre, 2019</h6>
                </div>
            </div>

            <div className="container col-12 col-sm-12 col-md-12 col-lg-10 col-xl-7 col-xxl-8 mx-auto text-center">
                <img src={PictureObjetivoArquitecturaPrincipal} className="w-100 my-4" />
            </div>

            <div className="container col-12 col-sm-12 col-md-12 col-lg-10 col-xl-7 col-xxl-5 mx-auto mt-4">

                <h5 className="lh-base fw-light">
                    Recuerdo una frase del Arq. Marco Aldaco (1933-2013), a quien tuve el gusto de conocer y aprender de su personalidad, su arquitectura y profunda sabiduría de vida:
                </h5>

                <h5 className="lh-base fw-light mt-4 border-2 p-4 border-start">
                    <i>“Nuestros clientes no tiene por qué vivir en un alarde de ingeniería y vanidad”</i>
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    Refiriéndose a la arquitectura residencial: la casa, el hogar.
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    ¿Qué pasa con la arquitectura, con el diseño de las casas?; ¿qué pasa por la mente de los arquitectos?
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    Desde la universidad se nos ha inculcado que los arquitectos venimos a resolver la “estética universal…” Que al proyectar debemos “echar a volar la imaginación…”: nuestros diseños deben ser creativos, innovadores; se nos lleva a comparar nuestros proyectos con los de los compañeros, revisar las tendencias internacionales, a participar en concursos, juzgar cuál proyecto es más “aventado”, desafiante, innovador, espectacular… Y así venimos a la vida profesional con esa carga anímica y de finalidad: La arquitectura tendrá mayor valor por su innovación y excentricidad. El resultado es que nos encontramos en nuestro entorno (pienso que en todas la ciudades…) ejemplos de arquitectura residencial que pareciera que más que enfocarse en la solución para la forma de vida particular e íntima de una familia, se enfoca en diseñar “arquitectura” para la fotografía, la revista o satisfacer el ego del arquitecto más que en las necesidades específicas de una familia en particular.
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    No niego la necesidad genuina y válida de que el diseño deba ser estéticamente agradable, atractivo y vanguardista; que se propongan formas y proporciones que hagan de la propuesta algo único y estético…; solo que existe el peligro de que -en el proceso-  este ingrediente del proyecto tome demasiada preponderancia en detrimento de la función propia y lógica de los espacios y necesidades reales de sus habitantes.
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    El diseño de un hogar debe centrarse en la belleza de la vida familiar, de sus relaciones y sus necesidades particulares (espaciales, psicológicas, simbólicas…); la arquitectura debe ser ese espacio concreto, formado por “cuatro paredes y un techo” que  facilite y enriquezca la relación entre sus ocupantes. Creo sin duda que una buena arquitectura une a la familia y una mala arquitectura  – si se le pudiera llamar arquitectura… –  es capaz de agredirla y destruirla sin que sus habitantes sean siquiera conscientes de ello…
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    Comentaba también el Arq. Marco Aldaco:
                </h5>

                <div className="row mt-4 align-items-center">
                    <div className="col-12 col-md-4">
                        <div class="card h-100">
                            <img src={PictureObjetivoArquitectura2} className="img-fluid" />
                        </div>
                    </div>
                    <div className="col">
                        <div class="card h-100 p-4 border-start">
                            <h5 className="lh-base fw-ligh border-2 ">
                                <i>“Posiblemente lo más bello de una casa es tener un lugar –una terraza– en dónde colocar una silla para disfrutar del paisaje al atardecer…, aunque la casa quede a  nuestras espaldas.”</i>
                            </h5>
                        </div>
                    </div>

                </div>



                <h5 className="lh-base fw-light mt-4">
                    La casa en sí no es lo importante; lo importante es todo aquello que esas cuatro paredes nos permiten, nos impulsan y nos mueven a vivir… La arquitectura debe ser una relación de espacios organizados con una intención, una dimensión y orden  claro: una escuela de vida, un espacio ordenado para el desarrollo de sus habitantes; una escuela y un orden para nosotros y nuestros hijos; una enseñanza de como entendemos la vida -y las relaciones familiares- y como deseamos transmitirla.
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    El proyecto de una vivienda no es una nueva oportunidad para la vanidad del arquitecto, para proponer un “capricho formal” preconcebido, un alarde de ingeniería o la utilización de los materiales más exóticos que encontremos en el mercado…
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    Hace años tuve oportunidad de visitar una casa en la que todos los espacios eran hexagonales;  desde luego que no estaba claro si el arquitecto pensó en su momento en la ubicación de los muebles…; resulta que a la hora de colocar las camas simplemente no se encontraba su lugar adecuado… Vemos residencias con los terminados  más exóticos, pero sin ventilaciones adecuadas; residencias que son un cubo de vidrio “perfecto”, pero que el asoleamiento las vuelve prácticamente inhabitables.
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    ¿Qué culpa tienen los clientes –futuros habitantes de nuestros diseños- para ser condenarlos a vivir – ¡y pagar! –  dentro de un capricho egocéntrico del arquitecto derivado de algún trauma no resuelto…?
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    Recuerdo el sentido común de mi hermano Gabriel, gran arquitecto: comentaba con cierta seriedad…:
                </h5>

                <h5 className="lh-base fw-light mt-4 border-2 p-4 border-start">
                    <i>‍“La filosofía básica de este despacho es: la puerta es puerta, la ventana es ventana, el muro es muro y el piso es piso…; no nos confundamos.”</i>
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    Con esto no quiero decir que hay que hacer de cada elemento de la arquitectura algo soso y aburrido, sino trabajar adecuadamente con estos elementos para lograr una excelente arquitectura sin pervertir la naturaleza y sencillez propia de cada elemento. Es importante que la fachada sea proporcionada y ordenada, pero la ventana se vive desde dentro; debe tener una ubicación en la habitación de acuerdo a su función, a lo que se vive, al amueblado, a las vistas, al clima, a la privacía… ¡Pero no en función a la proporción perfecta de la fachada “revistera”!. Ésta es la labor del arquitecto: lograr que la belleza del hogar sea el resultado del orden de sus elementos: si el diseño de la casa funciona correctamente, tendrá la oportunidad de ser bella naturalmente.
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    Pienso que lo más espectacular de una casa es  “que no tenga nada espectacular” (y esto sí que es un reto para el arquitecto…): no digo que no deba ser espectacular la casa, sino que su espectacularidad –y su belleza- nazca del orden con la que nace y de la armonía con la dinámica propia de vida de sus habitantes. Con la misma naturalidad que un rostro muestra su belleza: el rostro tiene una lógica y proporción a su función y seguramente mermaría su belleza si pretendiéramos mover la nariz de su lugar para ampliar la frente, agrandar un ojo para que sea “más espectacular” o  una oreja para que escuche mejor…
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    Lo vemos en la escultura, en la pintura…: toda obra humana expresa algo de su autor. Por esto es importante para los arquitectos ser conscientes de revisar desde dónde nace el diseño: si nace del conocimiento y respeto del cliente y su realidad; ¿cuál es nuestra intención profunda?; ¿qué necesidad deseamos satisfacer?; ¿qué es lo que deseamos expresar…?
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    Posiblemente lo más dramático en la vida del arquitecto es que va dejando su verdadera huella  – su esencia –  a la vista de todos…
                </h5>

                <h6 className="fw-light mt-4">
                    — Arq. Alfonso Núñez.
                </h6>

            </div>
        </>
    );
}

export default ObjetivoArquitectura;
