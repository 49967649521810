import "../../App.css"
import { Helmet } from "react-helmet"

function AvisoPrivacidad() {
    return (
        <>
            <Helmet>
                <title>Nosotros - Alfonso Nuñez Arquitectos</title>
            </Helmet>

            <section className="container col-12 col-md-10 col-lg-5 my-5">
                <h2 className=" text-center mb-5">Aviso de Privacidad</h2>
                <p>En <strong>Alfonso Núñez Arquitectos</strong>, nos comprometemos a proteger y respetar tu privacidad. Este aviso de privacidad describe cómo recopilamos, utilizamos y protegemos la información personal que nos proporcionas a través de nuestro formulario de contacto.</p>

                <h4>1. Responsables del Tratamiento de Datos</h4>
                <p><strong>Alfonso Núñez Arquitectos</strong>, con domicilio en <strong>Amado Nervo 154 Col. Ladrón de Guevara Guadalajara, Jalisco México.</strong>, es responsable del tratamiento de tus datos personales conforme a lo establecido en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares.</p>

                <h4>2. Datos Personales que Recopilamos</h4>
                <p>Los datos personales que podemos recopilar a través de nuestro formulario de contacto incluyen, pero no se limitan a:</p>
                <ul>
                    <li>Nombre completo</li>
                    <li>Dirección de correo electrónico</li>
                    <li>Número de teléfono</li>
                    <li>Cualquier otra información que decidas compartir en el campo de mensaje</li>
                </ul>

                <h4>3. Finalidad del Tratamiento de los Datos</h4>
                <p>La información que recopilemos será utilizada únicamente para los siguientes fines:</p>
                <ul>
                    <li>Atender tus consultas, solicitudes o comentarios</li>
                    <li>Mantener comunicación contigo en relación a los servicios que ofrecemos</li>
                    <li>Mejorar la calidad de nuestros servicios</li>
                </ul>

                <h4>4. Protección de tus Datos Personales</h4>
                <p>Implementamos las medidas de seguridad necesarias para proteger tus datos personales contra el acceso no autorizado, la alteración, divulgación o destrucción. Sin embargo, es importante señalar que ninguna transmisión de datos por internet es completamente segura.</p>

                <h4>5. Derechos ARCO</h4>
                <p>Tienes el derecho de Acceder, Rectificar, Cancelar u Oponerte al tratamiento de tus datos personales en cualquier momento. Para ejercer estos derechos, por favor envía un correo electrónico a <strong>contacto@alfonsonunez.net</strong> indicando tu solicitud.</p>

                <h4>6. Cambios al Aviso de Privacidad</h4>
                <p>Nos reservamos el derecho de actualizar este aviso de privacidad en cualquier momento. Cualquier cambio será publicado en esta misma página, por lo que te recomendamos revisarlo periódicamente.</p>

                <h4>7. Contacto</h4>
                <p>Si tienes alguna pregunta o inquietud sobre este aviso de privacidad, no dudes en contactarnos a través de <strong>contacto@alfonsonunez.net</strong> o en <strong>(52) 33 3100 2476</strong>.</p>
                <div className="mt-5 text-center">
                <a href="/" className="border-bottom">
                    Ir a la página principal
                </a>
                </div>
            </section>




        </>
    );
}

export default AvisoPrivacidad;
