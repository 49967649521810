import React, { useState } from "react";
import { Helmet } from "react-helmet";
import FsLightbox from "fslightbox-react";
import "../../../App.css"
import image2 from "../../../Img/Proyectos/ProyectosCasasDescanso/CasaHerradura/61ce6a918d36f13cf3bb8018_LaHerradura.2.jpg"
import image1 from "../../../Img/Proyectos/ProyectosCasasDescanso/CasaHerradura/61ce6a5bf9e0cafa4d8b883f_LaHerradura.1.jpg"
import image3 from "../../../Img/Proyectos/ProyectosCasasDescanso/CasaHerradura/61ce6ab8caa2661295bd370c_LaHerradura.4.jpg"
import image4 from "../../../Img/Proyectos/ProyectosCasasDescanso/CasaHerradura/61ce6abdfb6a101c44f8e39d_LaHerradura.6.jpg"
import image5 from "../../../Img/Proyectos/ProyectosCasasDescanso/CasaHerradura/61ce6ac2be32b1c153ee96d3_LaHerradura.7.jpg"
import image6 from "../../../Img/Proyectos/ProyectosCasasDescanso/CasaHerradura/61ce6ac78cdf72c68656f200_LaHerradura.8.jpg"
import image7 from "../../../Img/Proyectos/ProyectosCasasDescanso/CasaHerradura/61ce6a77caa2661e8cbd3633_LaHerradura.10.jpg"

function ProyectoHerraduraChacala() {

    const [toggler, setToggler] = useState(false);

    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });

    function openLightboxOnSlide(number) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number
        });
    }


    return (
        <>

            <Helmet>
                <title>Proyecto Residencial - Casa La Herradura, Chacala</title>
            </Helmet>

            <FsLightbox
                toggler={lightboxController.toggler}

                sources={[
                    image1, image2, image3, image4, image5, image6, image7
                ]}

                slide={lightboxController.slide}
            />

            <div className="container col-12 col-sm-12 col-md-12 col-lg-10 col-xl-8 col-xxl-5 mx-auto pb-4">
                <h1 className="py-4 text-center m-0 p-0">Casa La Herradura, Chacala</h1>
            </div>

            <div className="container position-relative">
                <img onClick={() => openLightboxOnSlide(1)} type="button" src={image1} className="w-100" alt="..." height={650} />
            </div>


            <div className="container">
                <div className="row gy-4 mt-1">

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(2)}
                            src={image2}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(3)}
                            src={image3}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(4)}
                            src={image4}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(5)}
                            src={image5}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(6)}
                            src={image6}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(7)}
                            src={image7}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                </div>
            </div>
        </>
    );
}

export default ProyectoHerraduraChacala;
