import React, { useState } from "react";
import { Helmet } from "react-helmet";
import FsLightbox from "fslightbox-react";
import "../../../App.css"
import image2 from "../../../Img/Proyectos/ProyectosDesarrollo/CasaTuCasa/651c47e075cc834ef59b19fa_ALFONSO NUÑEZ- EXTERIOR LATERAL POSTERIOR-05.jpg"
import image1 from "../../../Img/Proyectos/ProyectosDesarrollo/CasaTuCasa/651c47e034c3d0a59adb765f_ALFONSO NUÑEZ- EXTERIOR POSTERIOR-05.jpg"
import image3 from "../../../Img/Proyectos/ProyectosDesarrollo/CasaTuCasa/651c46d9fb7b9484da41ded4_SALA 03.jpg"

function ProyectoCasaTuCasa() {

    const [toggler, setToggler] = useState(false);

    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });

    function openLightboxOnSlide(number) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number
        });
    }


    return (
        <>

            <Helmet>
                <title>Playa y Montaña - Tapalpa C4</title>
            </Helmet>

            <FsLightbox
                toggler={lightboxController.toggler}

                sources={[
                    image1, image2, image3
                ]}

                slide={lightboxController.slide}
            />

            <div className="container col-12 py-5">
                <div className="d-flex">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/proyectos-casas-de-descanso">Playa y Montaña</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Tapalpa C4</li>
                        </ol>
                    </nav>
                </div>
                <h1 className="py-2 m-0 p-0">Tapalpa C4</h1>
                <h6 className="py-2 m-0 p-0">Tapalpa, Jal.<h6>2024</h6></h6>
            </div>

            <div className="container position-relative">
                <img onClick={() => openLightboxOnSlide(1)} type="button" src={image1} className="w-100" alt="..." height={650} />
            </div>


            <div className="container">
                <div className="row gy-4 mt-1">

                    <div className="col-lg-6 col-md-12">
                        <img onClick={() => openLightboxOnSlide(2)}
                            src={image2}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <img onClick={() => openLightboxOnSlide(3)}
                            src={image3}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                </div>
            </div>
        </>
    );
}

export default ProyectoCasaTuCasa;
