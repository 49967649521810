import "../App.css"
import Formulario from "../Components/Formulario";
import ThumPD from "../Img/Proyectos/ProyectosDesarrollo/ThumPD.jpg"
import ThumPCD from "../Img/Proyectos/ProyectosCasasDescanso/ThumPCD.jpg"
import ThumPR from "../Img/Proyectos/ProyectosResidenciales/ThumPR.jpg"
import ThumPT from "../Img/Proyectos/ProyectosTurismo/ThumPT.jpg"
import ThumIyC from "../Img/Proyectos/ProyectosIyC/ThumPIyC.jpg"
import PictureArqGuia from "../Img/Blogs/Guia-Arquitecto/arquitecto-sonriente-casco_23-2147702525.jpg"
import CarrouselImg2 from "../Img/Carrousel/61cb730a39c94d781d92ffe0_slide3_alfonso_2.jpg"
import CarrouselImg1 from "../Img/Carrousel/61ce062d8d36f1ba32b8fa15_1.jpg"
import CarrouselImg3 from "../Img/Carrousel/61cb83252e259d638ab8893b_casa_alberca_2.jpg"
import { Helmet } from 'react-helmet';


function HomeLaPaz() {
  return (
    <>

      <Helmet>
        <title>Alfonso Nuñez Arquitectos — La Paz</title>
        <meta name="description" content="Arquitectos en La Paz, Baja California Sur. Ofrecemos servicios de diseño arquitectónico y construcción."/>
        <meta name="keywords" content="arquitectos, La Paz, Baja California Sur, diseño arquitectónico, construcción, proyectos"/>
      </Helmet>


      <div className="container col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div id="carouselExampleInterval" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active" data-bs-interval="2000">
              <img src={CarrouselImg2} class="d-block w-100" alt="Imagen Carrusel" />
            </div>
            <div class="carousel-item" data-bs-interval="2000">
              <img src={CarrouselImg1} class="d-block w-100" alt="Imagen Carrusel" />
            </div>
            <div class="carousel-item" data-bs-interval="2000">
              <img src={CarrouselImg3} class="d-block w-100" alt="Imagen Carrusel" />
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>

      <div className="container col-12 col-sm-12 col-md-10 col-lg-12 col-xl-9">

        <div className="row align-items-top gy-3 py-5">

          <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <div class="card h-100">
              <h1 className="fw-medium m-0 p-0">Arquitectura contemporánea y funcional</h1>
            </div>
          </div>

          <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <div class="card h-100 rounded-0">
              <h6 className="lh-base fw-light">En <b className="fw-bold">Alfonso Núñez Arquitectos</b>, contamos con más de 30 años de experiencia en el desarrollo de proyectos arquitectónicos. Nos destacamos por diseñar y crear espacios funcionales, vanguardistas y contemporáneos.</h6>
              <h6 className="lh-base fw-light">Nuestra experiencia profesional se extiende desde nuestra sede en Guadalajara hasta diversas localidades en todo México.</h6>
              <a href="/nosotros" type="button" class="btn btn-outline-dark rounded-0 col-12 col-md-3 mt-3 btn-lg">Leer más</a>
            </div>
          </div>
        </div>

      </div>


      <section className="img-bg-comienza min-vh-100 d-flex flex-column justify-content-center align-items-center position-relative">
        <div className="overlay2 position-absolute w-100 h-100"></div>
        <div className="container text-center position-relative col-md-5">
          <h1 className="text-white mb-4 lh-sm">Damos vida a tus ideas y sueños, creando espacios únicos y de calidad</h1>
          <a type="button" href="/contacto" className="btn btn-light rounded-0 btn-lg">Hablemos de tu proyecto</a>
        </div>
      </section>

      <section>
        <div className="container text-center col-md-12">
          <h1 className="mb-4 lh-1 py-5">Proyectos</h1>

          <div class="row row-cols-1 row-cols-md-3">

            <div class="col">
              <div class="card h-100">
                <a href="/proyectos-en-desarrollo">
                  <img src={ThumPD} class="card-img-top h300" alt="Imagen Desarrollos" />
                  <div class="card-body">
                    <h5 class="card-title">En desarrollo</h5>
                  </div>
                </a>
              </div>
            </div>

            <div class="col">
              <div class="card h-100">
                <a href="/proyectos-casas-de-descanso">
                  <img src={ThumPCD} class="card-img-top h300" alt="Imagen Casas de Descanso" />
                  <div class="card-body">
                    <h5 class="card-title">Casas de descanso</h5>
                  </div>
                </a>
              </div>
            </div>

            <div class="col">
              <div class="card h-100">
                <a href="/proyectos-residenciales">
                  <img src={ThumPR} class="card-img-top h300" alt="Imagen de Residenciales" />
                  <div class="card-body">
                    <h5 class="card-title">Residenciales</h5>
                  </div>
                </a>
              </div>
            </div>

            <div class="col">
              <div class="card h-100">
                <a href="/proyectos-turismo">
                  <img src={ThumPT} class="card-img-top h300" alt="Imagen de Turismo" />
                  <div class="card-body">
                    <h5 class="card-title">Turismo</h5>
                  </div>
                </a>
              </div>
            </div>

            <div class="col">
              <div class="card h-100">
                <a href="/proyectos-industrial-y-comercial">
                  <img src={ThumIyC} class="card-img-top h300" alt="Imagen de Industrial y comercial" />
                  <div class="card-body">
                    <h5 class="card-title">Insustrial y comercial</h5>
                  </div>
                </a>
              </div>
            </div>

          </div>

        </div>
      </section>

      <section className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mx-auto pt-5 pb-5">

        <h1 className="lh-1 text-center">Blog</h1>

        <section className="container pt-3 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mx-auto">

          <div class="card pt-5">
            <a href="/guia-para-contratar-servicios-arquitectonicos">
              <div class="row bg-yellow align-items-center">
                <div class="col-md-6">
                  <img src={PictureArqGuia} class="img-blog" alt="Imagen de blog" />
                </div>
                <div class="col-md-6">
                  <div class="card-body">
                    <h6 class="small fw-light">20 de mayo, 2024</h6>
                    <h2 class="fw-medium my-4">Guía para contratar servicios arquitectónicos</h2>
                    <h6 className="small fw-light">Copyright 1989. The American Institute of Architects. <br />
                      Traducción y adaptación: Ámbito3, Arther y Alfonso Núñez-Arquitectos.</h6>
                  </div>
                </div>
              </div>
            </a>
          </div>

        </section>


      </section>

      <div class="container col-12 col-sm-12 col-md-12 col-lg-10 col-xl-4 mx-auto my-3">
        <h2 className="fw-medium text-center pb-5">Déjanos tus datos. <br />Te contactaremos a la brevedad.</h2>
        <Formulario />
      </div>

    </>
  );
}

export default HomeLaPaz;
