import React, { useState } from "react";
import { Helmet } from "react-helmet";
import FsLightbox from "fslightbox-react";
import "../../../App.css"
import image1 from "../../../Img/Proyectos/ProyectosResidenciales/ResidencialPO/651c6345f57440f0df3fe8c9_f.png"
import image2 from "../../../Img/Proyectos/ProyectosResidenciales/ResidencialPO/61ce0aefc237ed3564ded8bb_Casa Plasencia 2p.jpg"
import image3 from "../../../Img/Proyectos/ProyectosResidenciales/ResidencialPO/61ce0b0bc237ed3adaded9b4_Casa Plasencia 1.jpg"
import image4 from "../../../Img/Proyectos/ProyectosResidenciales/ResidencialPO/61ce0b38baf02f5f8ea17ff1_Casa Plasencia 12p.jpg"
import image5 from "../../../Img/Proyectos/ProyectosResidenciales/ResidencialPO/61ce0b39002f4d7206ac0193_Casa Plasencia 7.jpg"
import image6 from "../../../Img/Proyectos/ProyectosResidenciales/ResidencialPO/61ce0b393bb7540787858aca_Casa Plasencia 8.jpg"
import image7 from "../../../Img/Proyectos/ProyectosResidenciales/ResidencialPO/61ce0b3a8d36f16e61b92de1_Casa Plasencia 5.jpg"
import image8 from "../../../Img/Proyectos/ProyectosResidenciales/ResidencialPO/61ce0b3aec15b57385e8bfcc_Casa Plasencia 4p.jpg"

function ProyectoCasaPO() {

    const [toggler, setToggler] = useState(false);

    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });

    function openLightboxOnSlide(number) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number
        });
    }


    return (
        <>

            <Helmet>
                <title>Residencial - Residencial PO</title>
            </Helmet>

            <FsLightbox
                toggler={lightboxController.toggler}

                sources={[
                    image1, image2, image3, image4, image5, image6, image7, image8
                ]}

                slide={lightboxController.slide}
            />

            <div className="container col-12 py-5">
                <div className="d-flex">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/proyectos-residenciales">Residenciales</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Residencial PO</li>
                        </ol>
                    </nav>
                </div>
                <h1 className="py-2 m-0 p-0">Residencial PO</h1>
            </div>



            <div className="container position-relative">
                <img onClick={() => openLightboxOnSlide(1)} type="button" src={image1} className="w-100" alt="..." height={650} />
            </div>


            <div className="container">
                <div className="row gy-4 mt-1">

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(2)}
                            src={image2}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(3)}
                            src={image3}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(4)}
                            src={image4}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(5)}
                            src={image5}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(6)}
                            src={image6}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(7)}
                            src={image7}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>


                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(8)}
                            src={image8}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>



                </div>
            </div>
        </>
    );
}

export default ProyectoCasaPO;
