import "../App.css"
import React, { useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";

const Formulario = () => {
    const [formData, setFormData] = useState({
        nombre: '',
        apellido: '',
        email: '',
        telefono: '',
        mensaje: ''
    });

    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [recaptchaValue, setRecaptchaValue] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!recaptchaValue) {
            alert("Por favor, completa el reCAPTCHA");
            return;
        }

        try {
            const response = await fetch('https://godspeedcheckout.com/send-info-alfonso.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ ...formData, recaptchaValue })
            });

            if (response.ok) {
                console.log('Form successfully submitted');
                setConfirmationMessage('¡Gracias! Hemos recibido tu mensaje.');
                setFormData({ nombre: '', email: '', mensaje: '', apellido: '', telefono: '' });
                setRecaptchaValue(null);
            } else {
                console.error('Error submitting form');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <>
            <div>
                <form onSubmit={handleSubmit}>
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <label htmlFor="nombre" className="form-label">Nombre</label>
                                <input id="nombre" name="nombre" type="text" value={formData.nombre} onChange={handleChange} className="form-control" placeholder="" aria-label="First name" required />
                            </div>
                            <div className="col">
                                <label htmlFor="apellido" className="form-label">Apellido</label>
                                <input id="apellido" name="apellido" type="text" value={formData.apellido} onChange={handleChange} className="form-control" placeholder="" aria-label="Last name" required />
                            </div>
                            <div className="mt-3 mb-3">
                                <label htmlFor="email" className="form-label">Correo electrónico</label>
                                <input id="email" name="email" type="email" value={formData.email} onChange={handleChange} className="form-control" aria-describedby="emailHelp" required />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="telefono" className="form-label">Teléfono</label>
                                <input id="telefono" name="telefono" type="tel" value={formData.telefono} onChange={handleChange} className="form-control" required />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="mensaje" className="form-label">Mensaje</label>
                                <textarea id="mensaje" name="mensaje" className="form-control" onChange={handleChange} value={formData.mensaje} rows="3" placeholder="Tu mensaje" required></textarea>
                            </div>

                            <div className="col-12 mb-3">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="invalidCheck" required />
                                    <label className="form-check-label small" htmlFor="invalidCheck">
                                        He leído y acepto la <a href="/aviso-de-privacidad" className="border-bottom border-black">política de privacidad</a>.
                                    </label>
                                </div>
                            </div>

                            <div className="mb-3">
                                <ReCAPTCHA
                                    sitekey="6LfsSisqAAAAAFW_Ozk2_uqMo3B8u4vTIEAmN7em"
                                    onChange={(value) => setRecaptchaValue(value)}
                                />
                            </div>

                            <div className="d-grid gap-2 mt-2">
                                <button type="submit" className="btn btn-outline-dark rounded-0 btn-lg">Enviar</button>
                            </div>
                        </div>
                    </div>
                </form>
                {confirmationMessage && <p className="mt-4"><div className="alert alert-success rounded-0" role="alert"><i className="bi bi-check-circle-fill me-2"></i> {confirmationMessage}</div></p>}
            </div>
        </>
    );
};

export default Formulario;