import "../App.css"
import Logo from "../Img/ALFONSO-logo.svg"

function Nav() {
    return (
        <>
            <a href="https://wa.me/+523331002476" class="whatsapp-btn" target="_blank"> <i class="bi bi-whatsapp"></i></a>

            <div class="sticky-top">
                <nav class="navbar navbar-expand-lg py-4 bg-blur">
                    <div class="container d-flex justify-content-between align-items-center">
                        <a class="navbar-brand" href="/"><img className="logo-responsive" src={Logo} width={250} alt="Logotipo" /></a>
                        <button class="navbar-toggler border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarText">
                            <ul class="navbar-nav ms-auto gx-5">
                                <li class="nav-item small">
                                    <a class="nav-link active text-uppercase ls-0_5px me-4 fw-semibold" aria-current="page" href="/">Inicio</a>
                                </li>
                                <li class="nav-item small">
                                    <a class="nav-link active text-uppercase ls-0_5px me-4 fw-semibold" href="/proyectos">Proyectos</a>
                                </li>
                                <li class="nav-item small">
                                    <a class="nav-link active text-uppercase ls-0_5px me-4 fw-semibold" href="/nosotros">Conócenos</a>
                                </li>
                                <li class="nav-item small">
                                    <a class="nav-link active text-uppercase ls-0_5px me-4 fw-semibold" href="/blog">Blog</a>
                                </li>
                                <li class="nav-item small">
                                    <a class="nav-link active text-uppercase ls-0_5px me-4 fw-semibold" href="/galeria-acuarelas">Acuarelas</a>
                                </li>
                            </ul>
                            
                                <div class="d-grid gap-2">
                                    <a type="button" class="btn btn-outline-dark rounded-0 btn-lg" href="/contacto">Contáctanos</a>
                                </div>
                            
                        </div>
                    </div>
                </nav>
            </div>


            <div class="offcanvas offcanvas-start" data-bs-scroll="true" tabindex="-1" id="offcanvasWithBothOptions" aria-labelledby="offcanvasWithBothOptionsLabel">
                <div class="offcanvas-header">
                    <h5 class="offcanvas-title" id="offcanvasWithBothOptionsLabel">Backdrop with scrolling</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body">
                    <p>Try scrolling the rest of the page to see this option in action.</p>
                </div>
            </div>
        </>
    );
}

export default Nav;
