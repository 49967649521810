import React, { useState } from "react";
import { Helmet } from "react-helmet";
import FsLightbox from "fslightbox-react";
import "../../../App.css"
import image8 from "../../../Img/Proyectos/ProyectosDesarrollo/LomasAcueducto/651c618bde766cbf4abe855e_a.png"
import image2 from "../../../Img/Proyectos/ProyectosDesarrollo/LomasAcueducto/651c618e0d143e04dd25fdec_h.png"
import image3 from "../../../Img/Proyectos/ProyectosDesarrollo/LomasAcueducto/651c61947b2c1c3f1eb023fb_c1.png"
import image4 from "../../../Img/Proyectos/ProyectosDesarrollo/LomasAcueducto/651c6194eb96d379a096a92e_d1.png"
import image5 from "../../../Img/Proyectos/ProyectosDesarrollo/LomasAcueducto/651c61957b2c1c3f1eb0248a_A1.png"
import image6 from "../../../Img/Proyectos/ProyectosDesarrollo/LomasAcueducto/651c6195a2437662888e8f96_f1.png"
import image7 from "../../../Img/Proyectos/ProyectosDesarrollo/LomasAcueducto/651c6195d664fe89dcff4af7_e.png"
import image1 from "../../../Img/Proyectos/ProyectosDesarrollo/LomasAcueducto/651c6345f57440f0df3fe8c9_f.png"

function ProyectoLomasAcueducto() {

    const [toggler, setToggler] = useState(false);

    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });

    function openLightboxOnSlide(number) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number
        });
    }


    return (
        <>

            <Helmet>
                <title>Proyectos en Desarrollo - Casa Lomas Acueducto</title>
            </Helmet>

            <FsLightbox
                toggler={lightboxController.toggler}

                sources={[
                    image1, image2, image3, image4, image5, image6, image7, image8
                ]}

                slide={lightboxController.slide}
            />

            <div className="container col-12 col-sm-12 col-md-12 col-lg-10 col-xl-8 col-xxl-5 mx-auto pb-4">
                <h1 className="py-4 text-center m-0 p-0">Casa Lomas Acueducto</h1>
            </div>

            <div className="container position-relative">
                <img onClick={() => openLightboxOnSlide(1)} type="button" src={image1} className="w-100" alt="..." height={650} />
            </div>


            <div className="container">
                <div className="row gy-4 mt-1">

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(2)}
                            src={image2}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(3)}
                            src={image3}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(4)}
                            src={image4}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(5)}
                            src={image5}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>
                
                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(6)}
                            src={image6}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(7)}
                            src={image7}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(8)}
                            src={image8}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                </div>
            </div>
        </>
    );
}

export default ProyectoLomasAcueducto;
