import "../App.css"
import PictureArqGuia from "../Img/Blogs/Guia-Arquitecto/adult-architect-blueprint-416405-1-e1558802628675.webp"
import PictureAcabadosPrincipal from "../Img/Blogs/AcabadosDeLujo/1920_bantildeo.jpg"
import PictureArquitectoArtLujoPrincipal from "../Img/Blogs/ArquitectoArtLujo/61ce8a4065c416473ec830ff_bg_casa.webp"
import PictureArquitecturaSagradaPrincipal from "../Img/Blogs/HogarArquitecturaSagrada/606dc84b2a3044aa55e044cf_1.png"
import PictureArquitecturaInventarDescubrirPrincipal from "../Img/Blogs/ArquitecturaInventarDescubrir/61ce8847be32b1b5fbef299a_blog3.webp"
import PictureConsideracionesArquPrincipal from "../Img/Blogs/ConsideracionesArqu/Captura de pantalla 2024-08-28 203255.png"
import PictureObjetivoArquitecturaPrincipal from "../Img/Blogs/ObjetivoArquitectura/seguridad-hogar-vacaciones-1200x800.jpg"
import PictureMonArquiPrincipal from "../Img/Blogs/MonumentoAlArquitecto/Imagen de WhatsApp 2024-10-03 a las 10.48.31_28958698.jpg";
import { Helmet } from "react-helmet"

function Blog() {
    return (
        <>


            <Helmet>
                <title>Blog - Alfonso Nuñez Arquitectos</title>
            </Helmet>

            <section className="container pt-3 pb-5 col-12 col-sm-12 col-md-12 col-lg-12 mx-auto">

                <h1 className="mb-4 lh-1 py-5">Blog</h1>

                <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">

                    <div class="col">
                        <a href="/monumento-al-arquitecto-desconocido">
                            <div class="card h-100">
                                <img src={PictureMonArquiPrincipal} class="img-blog" alt="..." />
                                <div class="card-body">
                                    <h6 class="fw-light">29 de septiembre, 2024</h6>
                                    <h3 class="fw-medium my-4">Monumento al “arquitecto desconocido”…</h3>
                                </div>
                            </div>
                        </a>
                    </div>


                    <div class="col">
                        <a href="/guia-para-contratar-servicios-arquitectonicos">
                            <div class="card h-100">
                                <img src={PictureArqGuia} class="img-blog" alt="..." />
                                <div class="card-body">
                                    <h6 class="fw-light">10 de agosto, 2023</h6>
                                    <h3 class="fw-medium my-4">Guía para contratar servicios arquitectónicos</h3>
                                    <h6 className="fw-light">Copyright 1989. The American Institute of Architects. <br />
                                        Traducción y adaptación: Ámbito3, Arther y Alfonso Núñez-Arquitectos.</h6>
                                </div>
                            </div>
                        </a>
                    </div>


                    <div class="col">
                        <a href="/acabados-de-lujo">
                            <div class="card h-100">
                                <img src={PictureAcabadosPrincipal} class="img-blog" alt="Imagen Blog" />
                                <div class="card-body">
                                    <h6 class="fw-light">20 de mayo, 2021</h6>
                                    <h3 class="fw-medium my-4">¡Con acabados de lujo!</h3>
                                </div>
                            </div>
                        </a>
                    </div>


                    <div class="col">
                        <a href="/el-hogar-arquitectura-sagrada">
                            <div class="card h-100">
                                <img src={PictureArquitecturaSagradaPrincipal} class="img-blog" alt="Imagen Blog" />
                                <div class="card-body">
                                    <h6 class=" fw-light">23 de marzo, 2021</h6>
                                    <h3 class="fw-medium my-4">El Hogar: “Arquitectura Sagrada”</h3>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col">
                        <a href="/el-arquitecto-articulo-de-lujo">
                            <div class="card h-100">
                                <img src={PictureArquitectoArtLujoPrincipal} class="img-blog" alt="Imagen Blog" />
                                <div class="card-body">
                                    <h6 class=" fw-light">03 de febrero, 2021</h6>
                                    <h3 class="fw-medium my-4">El Arquitecto ¿Artículo de lujo?</h3>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col">
                        <a href="/arquitectura-inventar-o-descubrir">
                            <div class="card h-100">
                                <img src={PictureArquitecturaInventarDescubrirPrincipal} class="img-blog" alt="Imagen Blog" />
                                <div class="card-body">
                                    <h6 class=" fw-light">10 de agosto, 2020</h6>
                                    <h3 class="fw-medium my-4">Arquitectura: ¿Inventar O Descubrir?</h3>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col">
                        <a href="/el-objetivo-no-es-la-arquitectura-sino-lo-que-permitimos-que-suceda-en-ella">
                            <div class="card h-100">
                                <img src={PictureObjetivoArquitecturaPrincipal} class="img-blog" alt="Imagen Blog" />
                                <div class="card-body">
                                    <h6 class=" fw-light">1 de octubre, 2019</h6>
                                    <h3 class="fw-medium my-4">El objetivo no es la arquitectura, sino lo que permitimos que suceda en ella</h3>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col">
                        <a href="/consideraciones-sobre-arquitectura">
                            <div class="card h-100">
                                <img src={PictureConsideracionesArquPrincipal} class="img-blog" alt="Imagen Blog" />
                                <div class="card-body">
                                    <h6 class=" fw-light">11 de agosto, 2019</h6>
                                    <h3 class="fw-medium my-4">Consideraciones sobre arquitectura</h3>
                                </div>
                            </div>
                        </a>
                    </div>




                </div>

            </section>

        </>
    );
}

export default Blog;
