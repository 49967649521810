import React, { useState } from "react";
import { Helmet } from "react-helmet";
import FsLightbox from "fslightbox-react";
import "../../../App.css"
import image7 from "../../../Img/Proyectos/ProyectosCasasDescanso/CasaSinFin/61ce6c3665c416cc62c78f02_100_5662.jpg"
import image2 from "../../../Img/Proyectos/ProyectosCasasDescanso/CasaSinFin/61ce6c665599ff575527e284_100_5660.jpg"
import image3 from "../../../Img/Proyectos/ProyectosCasasDescanso/CasaSinFin/61ce6ca78cdf721a7556f8c2_100_5707.jpg"
import image4 from "../../../Img/Proyectos/ProyectosCasasDescanso/CasaSinFin/61ce6caa8a518132b439c891_100_5688.jpg"
import image5 from "../../../Img/Proyectos/ProyectosCasasDescanso/CasaSinFin/61ce6caabaf02f6606a3be38_100_5700.jpg"
import image6 from "../../../Img/Proyectos/ProyectosCasasDescanso/CasaSinFin/61ce6cab57f55251b4a09cd6_100_5680.jpg"
import image1 from "../../../Img/Proyectos/ProyectosCasasDescanso/CasaSinFin/61ce6cac8a5181836239c893_100_5682.jpg"

function ProyectoCasaChacala() {

    const [toggler, setToggler] = useState(false);

    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });

    function openLightboxOnSlide(number) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number
        });
    }


    return (
        <>

            <Helmet>
                <title>Proyecto Residencial - Casa sin Fin, Chacala</title>
            </Helmet>

            <FsLightbox
                toggler={lightboxController.toggler}

                sources={[
                    image1, image2, image3, image4, image5, image6, image7
                ]}

                slide={lightboxController.slide}
            />

            <div className="container col-12 col-sm-12 col-md-12 col-lg-10 col-xl-8 col-xxl-5 mx-auto pb-4">
                <h1 className="py-4 text-center m-0 p-0">Casa sin Fin, Chacala</h1>
            </div>

            <div className="container position-relative">
                <img onClick={() => openLightboxOnSlide(1)} type="button" src={image1} className="w-100" alt="..." height={650} />
            </div>


            <div className="container">
                <div className="row gy-4 mt-1">

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(2)}
                            src={image2}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(3)}
                            src={image3}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(4)}
                            src={image4}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(5)}
                            src={image5}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(6)}
                            src={image6}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(7)}
                            src={image7}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                </div>
            </div>
        </>
    );
}

export default ProyectoCasaChacala;
