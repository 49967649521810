import "../../App.css";
import { Helmet } from 'react-helmet';
import PictureArquitectoArtLujoPrincipal from "../../Img/Blogs/ArquitectoArtLujo/61ce8a4065c416473ec830ff_bg_casa.webp"
import PictureArquitectoArtLujo1 from "../../Img/Blogs/ArquitectoArtLujo/61ce8a4065c416473ec830ff_bg_casa_1.webp"
import PictureArquitectoArtLujo3 from "../../Img/Blogs/ArquitectoArtLujo/61ce8a4065c416473ec830ff_bg_casa_3.webp"
import PictureArquitectoArtLujo2 from "../../Img/Blogs/ArquitectoArtLujo/61ce8a4065c416473ec830ff_bg_casa_2.webp"


function ArquitectoArtLujo() {




    return (
        <>
            <Helmet>
                <title>Blog - El Arquitecto ¿Artículo de lujo?</title>
                <meta property="og:title" content="Guía para contratar servicios arquitectónicos" />
                <meta property="og:description" content="Aprende cómo contratar los mejores servicios arquitectónicos con nuestra guía completa. Descubre qué preguntar, cómo seleccionar un arquitecto y más. ¡Lee ahora!" />
                <meta property="og:url" content="https://demo-alfredo.godspeedcheckout.com" />
                <meta property="og:image" content="../../../public/Blogs/Guia-Arquitecto/arquitecto-sonriente-casco_23-2147702525.jpg" />
                <meta property="og:type" content="article" />
            </Helmet>

            <div className="container col-12 col-sm-12 col-md-12 col-lg-10 col-xl-7 col-xxl-8 py-4">
                <div className="col-12">
                    <div className="d-flex">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/blog">Blog</a></li>
                                <li class="breadcrumb-item active" aria-current="page">¡Con acabados de lujo!</li>
                            </ol>
                        </nav>
                    </div>
                    <h1 className="py-2 m-0 p-0">El Arquitecto ¿Artículo de lujo?</h1>
                    <h6 class="fw-light mt-3">03 de febrero, 2021</h6>
                </div>
            </div>


            <div className="container col-12 col-sm-12 col-md-12 col-lg-10 col-xl-7 col-xxl-8 mx-auto">
                <img src={PictureArquitectoArtLujoPrincipal} className="w-100 my-4" alt="Imagen Blog" />
            </div>

            <div className="container col-12 col-sm-12 col-md-12 col-lg-10 col-xl-7 col-xxl-5 mx-auto mt-4">
                <h5 className="lh-base fw-light">
                    Supongo que por cierto desconocimiento sobre la labor del arquitecto en un proyecto de construcción, me he encontrado con cierta frecuencia en mi vida profesional con la realidad de que algunos posibles clientes consideran la labor del arquitecto, si no como algo prescindible, sí como un “rubro costoso” en un proyecto y que es uno de esos conceptos en los que es importante ahorrar al momento de construir. Con el tiempo, me he encontrado los consabidos comentarios:
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    <i> “Arquitecto, ¿Es lo más barato…?”; “¿Y si me haces solo unos ‘planitos’ y el resto yo lo llevo junto con mi maestro de obras que tiene mucha experiencia y  le tengo confianza…?”; “¿Por qué gastar tanto en planos?, podemos copiar el proyecto de mi vecino…”; “Mi hijo está estudiando arquitectura…”; “El ingeniero me regalará el proyecto si le encargo la construcción”; “Tengo un buen amigo arquitecto que me rebaja sus honorarios a la mitad…”.</i>
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    Parecerá oneroso que unos cuantas líneas sobre papel tengan determinado precio, pero es importante conocer y visualizar que el proceso y costo de contar con un proyecto personalizado requiere muchas horas de trabajo y recursos técnicos…; ésto sin contar con los conocimientos y años de experiencia necesarios para que el proyecto realmente cumpla con las expectativas, necesidades, y requerimientos normativos y técnicos.
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    Una vez que tenemos resuelto el tema del terreno en donde deseamos construir nuestro proyecto, el primer rubro en el que debemos destinar recursos es en buscar y contratar el arquitecto que deberá realizar el proyecto…; y será tentador que ya deseemos ahorrar recursos desde este primer desembolso…
                </h5>

                <h5 className="lh-base mt-4 fw-light">
                    Un nuevo proyecto de construcción –casa, oficinas, comercio…- nace siempre de una necesidad específica, pero no siempre se cuenta con recursos inagotables para realizar dicho proyecto; más comúnmente es que nos adentremos en la aventura de construir con una base económica limitada y -con frecuencia- con la perspectiva de conseguir los recursos necesarios para terminar en el transcurso que lleve la construcción, de tal manera que cuidar los costos de nuestro proyecto siempre es una prioridad… Así que la limitación de los recursos nos llevará a buscar constantemente ahorros en los distintos rubros de la construcción.
                </h5>

                <img src={PictureArquitectoArtLujo1} className="w-100 mt-4" alt="Imagen Blog" />

                <h5 className="lh-base fw-light mt-4">
                    Arquitectura no son líneas sobre un papel; es mucho más que eso y requiere conocimientos y experiencia de un profesional, de la misma manera que es necesario un abogado para un contrato adecuado, un médico para realizar una operación o un piloto para volar un avión… Así como normalmente acudimos a un profesional de confianza, será de sentido común acudir a un arquitecto con cartas suficientes para que nos dé certeza en el resultado de nuestra inversión, que bien puede ser gran parte de nuestro patrimonio…
                </h5>

                <h3 className="lh-base fw-light mt-4 fw-semibold">
                    Estas son algunas tareas que realiza el arquitecto:
                </h3>
                
                <h5 className="lh-base fw-light mt-4">
                    1. El arquitecto revisara la normativa para ajustarse a ella, ahorrándonos tiempo y dinero en la tramitología.<br/><br/>
                    2. Nos proveerá de un proyecto personalizado en el que se organizan y optimicen los espacio para nuestras necesidades y en el que se aprovechen las condiciones del entorno: clima, ventilación, asoleamiento…; le dará una estética acorde a nuestras expectativas.<br/><br/>
                    3. Preparará un presupuesto base que nos orientará si nuestras expectativas se ajustan a nuestro presupuesto.<br/><br/>
                    4. Por medio de planos, maquetas y renders nos mostrará como en realidad será el proyecto, de manera que podremos revisarlo y visualizarnos viviéndolo antes de comenzar la construcción, evitando los cambios costosos ya iniciado el proceso de construcción.<br/><br/>
                    5. Nos acompañará en el proceso constructivo en el que –inevitablemente- habrá cientos de decisiones que tomar. Nos ayudará a elegir los materiales y precios adecuados para nuestro proyecto.<br/><br/>
                </h5>
               
                <img src={PictureArquitectoArtLujo2} className="w-100 my-4" alt="Imagen Blog" />

                <h5 className="lh-base fw-light mt-4">
                    Su trabajo es aportar plusvalía a nuestra inversión; una construcción con un buen proyecto no cuesta más, pero sí vale más…
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    El arquitecto es el único que tiene en su mente -y en sus planos- el proyecto en su totalidad;  junto con el constructor, sabrá dirigir los esfuerzos para llevarlo a buen término. Si bien, es conveniente buscar un arquitecto de acuerdo a nuestro presupuesto y posibilidades, es importante tener en cuenta que es justamente él quien nos puede guiar para encontrar la mejor manera de ahorrar en los rubros en donde sí sea conveniente hacerlo y ajustarnos a nuestro presupuesto.
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    Construir es literalmente colocar “billetes” –nuestro patrimonio- sobre el terreno…;  alguien con sabiduría y experiencia deberá cuidar que se haga con responsabilidad. Al acudir a un arquitecto no solamente cuidamos y ahorramos dinero, sino que añadimos valor a nuestra inversión, siendo éste el camino más seguro para obtener el proyecto que necesitamos.
                </h5>

                <img src={PictureArquitectoArtLujo3} className="w-100 mt-4" alt="Imagen Blog" />

                <h6 className="fw-light mt-4">
                    — Arq. Alfonso Núñez.
                </h6>

            </div>
        </>
    );
}

export default ArquitectoArtLujo;
