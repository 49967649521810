import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Importa los estilos CSS
import { Carousel } from 'react-responsive-carousel';

const AcuarelasPageDos = () => (

    

    <section className='bg-dark pt-3'>

        

        <div className='container '>


            <Carousel
                showArrows={true}
                showStatus={true}
                showThumbs={true}
                infiniteLoop={true}
                autoPlay={true}
                emulateTouch={true}
            >
                <div style={{ width: '100%', height: '500px', overflow: 'hidden' }}>
                    <img
                        src="/Acuarelas/1.Palacio Legislativo, Guanajuato (Detalle).jpg"
                        alt="Image 1"
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                    />
                </div>
                <div style={{ width: '100%', height: '500px', overflow: 'hidden' }}>
                    <img
                        src="/Acuarelas/1Palacio Legislativo, Guanajuato.jpg"
                        alt="Image 2"
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                    />
                </div>
                <div style={{ width: '100%', height: '500px', overflow: 'hidden' }}>
                    <img
                        src="/Acuarelas/2.Jardín Reforma, Guanajuato.jpg"
                        alt="Image 2"
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                    />
                </div>
                <div style={{ width: '100%', height: '500px', overflow: 'hidden' }}>
                    <img
                        src="/Acuarelas/3 Teatro Juárez, Guanajuato.jpg"
                        alt="Image 2"
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                    />
                </div>
                <div style={{ width: '100%', height: '500px', overflow: 'hidden' }}>
                    <img
                        src="/Acuarelas/3. Teatro Juárez, Guanajuato (Detalle).jpg"
                        alt="Image 2"
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                    />
                </div>
                <div style={{ width: '100%', height: '500px', overflow: 'hidden' }}>
                    <img
                        src="/Acuarelas/4. Calle Subterránea, Guanajuato.jpg"
                        alt="Image 2"
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                    />
                </div>

                {/* Repite para el resto de las imágenes */}

                <div style={{ width: '100%', height: '500px', overflow: 'hidden' }}>
                    <img
                        src="/Acuarelas/5 Plaza del Baratillo, Guanajuato (Detalle).jpg"
                        alt="Image 2"
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                    />
                </div>

                <div style={{ width: '100%', height: '500px', overflow: 'hidden' }}>
                    <img
                        src="/Acuarelas/5 Plaza del Baratillo, Guanajuato.jpg"
                        alt="Image 2"
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                    />
                </div>

                <div style={{ width: '100%', height: '500px', overflow: 'hidden' }}>
                    <img
                        src="/Acuarelas/6 Guanajuato.jpg"
                        alt="Image 2"
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                    />
                </div>

                <div style={{ width: '100%', height: '500px', overflow: 'hidden' }}>
                    <img
                        src="/Acuarelas/6 La Valenciana, Guanajuato.jpg"
                        alt="Image 2"
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                    />
                </div>

                <div style={{ width: '100%', height: '500px', overflow: 'hidden' }}>
                    <img
                        src="/Acuarelas/6 Mercado Hidalgo, Guanajuato (Detalle).jpg"
                        alt="Image 2"
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                    />
                </div>

                <div style={{ width: '100%', height: '500px', overflow: 'hidden' }}>
                    <img
                        src="/Acuarelas/6 Mercado Hidalgo, Guanajuato.jpg"
                        alt="Image 2"
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                    />
                </div>

                <div style={{ width: '100%', height: '500px', overflow: 'hidden' }}>
                    <img
                        src="/Acuarelas/7 Amanecer.jpg"
                        alt="Image 2"
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                    />
                </div>

                <div style={{ width: '100%', height: '500px', overflow: 'hidden' }}>
                    <img
                        src="/Acuarelas/8 Atardecer.jpg"
                        alt="Image 2"
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                    />
                </div>

                <div style={{ width: '100%', height: '500px', overflow: 'hidden' }}>
                    <img
                        src="/Acuarelas/9 Atrio Zapopan (Guadalajara).jpg"
                        alt="Image 2"
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                    />
                </div>

                <div style={{ width: '100%', height: '500px', overflow: 'hidden' }}>
                    <img
                        src="/Acuarelas/10 Bellas Artes (Detalle).jpg"
                        alt="Image 2"
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                    />
                </div>

                <div style={{ width: '100%', height: '500px', overflow: 'hidden' }}>
                    <img
                        src="/Acuarelas/11 Calle Moneda (México DF).jpg"
                        alt="Image 2"
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                    />
                </div>

                <div style={{ width: '100%', height: '500px', overflow: 'hidden' }}>
                    <img
                        src="/Acuarelas/16 El Angel (México DF).jpg"
                        alt="Image 2"
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                    />
                </div>

                <div style={{ width: '100%', height: '500px', overflow: 'hidden' }}>
                    <img
                        src="/Acuarelas/17 El Caballito 1.jpg"
                        alt="Image 2"
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                    />
                </div>

                <div style={{ width: '100%', height: '500px', overflow: 'hidden' }}>
                    <img
                        src="/Acuarelas/17 MUNAL (Detalle).jpg"
                        alt="Image 2"
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                    />
                </div>

                <div style={{ width: '100%', height: '500px', overflow: 'hidden' }}>
                    <img
                        src="/Acuarelas/17 MUNAL DF.jpg"
                        alt="Image 2"
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                    />
                </div>

                <div style={{ width: '100%', height: '500px', overflow: 'hidden' }}>
                    <img
                        src="/Acuarelas/18 El Carmen (México DF).jpg"
                        alt="Image 2"
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                    />
                </div>

                <div style={{ width: '100%', height: '500px', overflow: 'hidden' }}>
                    <img
                        src="/Acuarelas/19 En el Hospicio (Guadalajara).jpg"
                        alt="Image 2"
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                    />
                </div>

                <div style={{ width: '100%', height: '500px', overflow: 'hidden' }}>
                    <img
                        src="/Acuarelas/20 En Guadalajara.jpg"
                        alt="Image 2"
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                    />
                </div>

                <div style={{ width: '100%', height: '500px', overflow: 'hidden' }}>
                    <img
                        src="/Acuarelas/21 En la Mancha.jpg"
                        alt="Image 2"
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                    />
                </div>

                <div style={{ width: '100%', height: '500px', overflow: 'hidden' }}>
                    <img
                        src="/Acuarelas/22 En la Montaña.jpg"
                        alt="Image 2"
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                    />
                </div>


                <div style={{ width: '100%', height: '500px', overflow: 'hidden' }}>
                    <img
                        src="/Acuarelas/24 En Taxco.jpg"
                        alt="Image 2"
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                    />
                </div>

                <div style={{ width: '100%', height: '500px', overflow: 'hidden' }}>
                    <img
                        src="/Acuarelas/25 En Taxo.jpg"
                        alt="Image 2"
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                    />
                </div>

                <div style={{ width: '100%', height: '500px', overflow: 'hidden' }}>
                    <img
                        src="/Acuarelas/26 Exconvento del Desierto de los Leones (Mexico DF).jpg"
                        alt="Image 2"
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                    />
                </div>

                <div style={{ width: '100%', height: '500px', overflow: 'hidden' }}>
                    <img
                        src="/Acuarelas/27 Expiatorio (Guadalajara).jpg"
                        alt="Image 2"
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                    />
                </div>

                <div style={{ width: '100%', height: '500px', overflow: 'hidden' }}>
                    <img
                        src="/Acuarelas/27 Portada Expiatorio (Guadalajara).jpg"
                        alt="Image 2"
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                    />
                </div>

                <div style={{ width: '100%', height: '500px', overflow: 'hidden' }}>
                    <img
                        src="/Acuarelas/28 Hospicio Cabañas, Guadalajara.jpg"
                        alt="Image 2"
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                    />
                </div>











            </Carousel>
        </div>
    </section>
);

export default AcuarelasPageDos;
