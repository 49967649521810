import React, { useState } from "react";
import { Helmet } from "react-helmet";
import FsLightbox from "fslightbox-react";
import "../../../App.css"
import image1 from "../../../Img/Proyectos/ProyectosDesarrollo/SportHabitat/lamina.jpg"


function ProyectoSportHabitatPV() {

    const [toggler, setToggler] = useState(false);

    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });

    function openLightboxOnSlide(number) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number
        });
    }


    return (
        <>

            <Helmet>
                <title>Varios - Condominio Sport Habitat</title>
            </Helmet>

            <FsLightbox
                toggler={lightboxController.toggler}

                sources={[
                    image1
                ]}

                slide={lightboxController.slide}
            />

            <div className="container col-12 py-5">
                <div className="d-flex">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/proyectos-en-desarrollo">Varios</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Condominio Sport Habitat</li>
                        </ol>
                    </nav>
                </div>
                <h1 className="py-2 m-0 p-0">Condominio Sport Habitat</h1>
                <h6 className="py-2 m-0 p-0">Puerto Vallarta, Jal.<h6>2006</h6></h6>
            </div>

            <div className="container position-relative">
                <img onClick={() => openLightboxOnSlide(1)} type="button" src={image1} className="w-100" alt="..." height={650} />
            </div>
            
        </>
    );
}

export default ProyectoSportHabitatPV;
