import "../../App.css";
import { Helmet } from "react-helmet";
import PictureMonArqui from "../../Img/Blogs/MonumentoAlArquitecto/Imagen de WhatsApp 2024-10-03 a las 10.48.31_28958698.jpg";
import PictureMonArqui2 from "../../Img/Blogs/MonumentoAlArquitecto/Imagen de WhatsApp 2024-10-03 a las 10.48.30_19dbd342.jpg";
import PictureMonArqui3 from "../../Img/Blogs/MonumentoAlArquitecto/Imagen de WhatsApp 2024-10-03 a las 10.48.31_e80eca3e.jpg";
import PictureMonArqui4 from "../../Img/Blogs/MonumentoAlArquitecto/Imagen de WhatsApp 2024-10-03 a las 10.48.31_86e50546.jpg";

function MonumentoAlArquitecto() {
    return (
        <>
            <Helmet>
                <title>Blog - Monumento al “arquitecto desconocido”…</title>
                <meta
                    property="og:title"
                    content=" Monumento al “arquitecto desconocido”…"
                />
                <meta
                    property="og:description"
                    content="Aprende cómo contratar los mejores servicios arquitectónicos con nuestra guía completa. Descubre qué preguntar, cómo seleccionar un arquitecto y más. ¡Lee ahora!"
                />
                <meta
                    property="og:url"
                    content="https://demo-alfredo.godspeedcheckout.com"
                />
                <meta
                    property="og:image"
                    content="../../../public/Blogs/Guia-Arquitecto/arquitecto-sonriente-casco_23-2147702525.jpg"
                />
                <meta property="og:type" content="article" />
            </Helmet>

            <div className="container col-12 col-sm-12 col-md-12 col-lg-10 col-xl-7 col-xxl-8 py-4">
                <div className="col-12">
                    <div className="d-flex">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item">
                                    <a href="/blog">Blog</a>
                                </li>
                                <li
                                    class="breadcrumb-item active text-break"
                                    aria-current="page"
                                >
                                    Monumento al “arquitecto desconocido”…
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <h1 className="py-2 m-0 p-0">
                        Monumento al “arquitecto desconocido”…
                    </h1>
                    <h6 class="fw-light mt-3">29 de septiembre, 2024</h6>
                </div>
            </div>

            <div className="container col-12 col-sm-12 col-md-12 col-lg-10 col-xl-7 col-xxl-8 mx-auto">
                <img src={PictureMonArqui} className="w-100 my-4" alt="Imagen blog" />
            </div>

            <div className="container col-12 col-sm-12 col-md-12 col-lg-10 col-xl-7 col-xxl-5 mx-auto mt-4">
                <h5 className="lh-base fw-light">
                    Recuerdo una ocasión en la universidad, en clase de{" "}
                    <i> “Teoría de la Arquitectura”</i>, el Arq. Germán Herrasti -gran
                    maestro y amigo-, nos comentaba sobre la importancia de esforzarnos
                    por realizar buenos proyectos, no para acrecentar nuestro ego de
                    arquitecto sino simple y llanamente realizarlas correctamente... Nos
                    comentaba que faltaba en este mundo el{" "}
                    <i>“Monumento al Arquitecto Desconocido”</i>, aquel profesional que se
                    esforzaba en realizar su proyecto de la mejor manera posible para el
                    beneficio de sus moradores, del contexto y de la ciudad, sin la
                    pretensión de realizar una obra de arte, espectacular, y firmarla para
                    pasar a la posteridad…
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    Pareciera existir en el mundo de la arquitectura una competencia por
                    realizar el proyecto más inusual, insólito o extraño para sobresalir
                    del resto... En esta carrera un tanto irracional se llega a verdaderos
                    absurdos, como construir casas en forma de caja de vidrio, edificios
                    en forma de tornillo, fachadas que parece que se están derritiendo,
                    edificios en forma de canasta del mercado, de zapato, con forma de
                    pez, de barco, de nave espacial… (basta ver algunas revistas de
                    arquitectura…). El tema no es lo extraño de la forma, sino que
                    frecuentemente se olvidan de lo más importante: las necesidades de las
                    personas que lo habitarán: lo que sentirán y pensarán al vivir esos
                    espacios…
                </h5>

                <div class="row my-5">
                    <div class="col">
                        <img src={PictureMonArqui2} className="w-100" alt="Imagen blog" />
                    </div>
                    <div class="col">
                        <img src={PictureMonArqui3} className="w-100" alt="Imagen blog" />
                    </div>
                </div>

                <h5 className="lh-base fw-light mt-4">
                    Esta competencia pareciera que está allá lejos en los grandes
                    proyectos íconos de la ciudad, pero de alguna manera esta forma de
                    proyectar se transmite a la arquitectura más común y ordinaria: una
                    casa habitación no tiene porqué competir en un concurso de proyectos
                    futurista, exóticos, de vanguardia, o ser un alarde de ingeniería…
                    ¿Por qué el cliente tiene que vivir dentro del sueño y vanidad del
                    arquitecto y no dentro de sus propios sueños…?
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    Claro está que la arquitectura, siendo una de las <i>Bellas Artes</i>,
                    siempre tendrá - ¡y deberá tener! - un ingrediente artístico, estético
                    y único que es la expresión personal del arquitecto; posiblemente algo
                    que le haga sobresalir de los demás y ganarse un merecido prestigio y
                    preferencia…; ésto desde luego que no está mal, y es un ingrediente
                    esencial en la arquitectura.
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    Con el tiempo he acuñado una frase que de alguna manera es un reto
                    para la arquitectura: <i>“Lo más espectacular de una casa es que no tenga
                        nada espectacular”</i>; no significa en absoluto pretender prescindir de
                    intención creativa al proyecto, o de atención al detalle… Es más bien
                    la manera y forma en que se canaliza el esfuerzo del arquitecto:
                    resolver las necesidades del cliente o sus propias necesidades.
                    Cuántas veces hemos visto casas espectaculares con detalles
                    espectaculares: La puerta de ingreso es una losa pétrea de 2 toneladas
                    que gira mágicamente al impulsarla con un dedo; el piso de vidrio de
                    una terraza sobre el acantilado en el que nadie se siente seguro; la
                    sala que mide 10 x 15 metros en la que no hay manera de amueblar o
                    sentirse en intimidad; vivir en una caja de vidrio en la que es
                    difícil sentir privacía, es imposible colgar nuestros cuadros y estar
                    condenados a vivir con las cortinas cerradas permanentemente…
                </h5>

                <img
                    src={PictureMonArqui4}
                    className="w-100 mt-4 mb-2"
                    alt="Imagen blog"
                />

                <h5 className="lh-base fw-light mt-4">
                    Por otro lado, posiblemente hemos tenido la oportunidad de visitar
                    alguna casa –en la ciudad o en el campo- que se nos antoja la casa de
                    nuestros sueños; pero que si la observamos detenidamente no le
                    encontramos nada especialmente sobresaliente o espectacular…:
                    seguramente tendría algo que nos llamara la atención; la paz y
                    tranquilidad que nos provoca; la organización interna y proporción de
                    sus espacios; su relación con el entorno; el tratamiento de la luz; la
                    sencillez de materiales; el discreto diseño del jardín… Espectacular
                    es la paz y armonía que vivimos ahí…; ¿No será la verdadera labor del
                    arquitecto, más allá de buscar y proponer formas novedosas, permitir
                    estas vivencias, enriquecer la vida de sus habitantes…? ¿No será esto
                    lo verdaderamente espectacular de un hogar…?
                </h5>

                <h5 className="lh-base fw-light">
                    Observar felices a los clientes al llegar y habitar su nueva casa,
                    discreta y adecuada -justo lo que deseaban y necesitaban- es
                    sencillamente espectacular… Ésto, sin lugar a duda, es la mayor
                    satisfacción para el arquitecto…
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    Posiblemente se necesiten más <i>“arquitectos desconocidos”</i> que se
                    preocupen en mayor medida de las necesidades de los clientes y un poco
                    menos de la oportunidad de pasar a la historia en cada uno de sus
                    proyectos.
                </h5>

                <h6 className="fw-light mt-4">— Arq. Alfonso Núñez.</h6>
            </div>
        </>
    );
}

export default MonumentoAlArquitecto;
