import React, { useState } from "react";
import { Helmet } from "react-helmet";
import FsLightbox from "fslightbox-react";
import "../../../App.css"
import image1 from "../../../Img/Proyectos/ProyectosResidenciales/CasaManantial/61ce0c0d03a8ee20bfa803ed_102_0317.jpg"
import image2 from "../../../Img/Proyectos/ProyectosResidenciales/CasaManantial/61ce0c955290504a34fc5b9b_102_0406.jpg"
import image3 from "../../../Img/Proyectos/ProyectosResidenciales/CasaManantial/61ce0c02e0282e1c93387867_Casa-Nuno-3p.jpg"
import image4 from "../../../Img/Proyectos/ProyectosResidenciales/CasaManantial/61ce0d1d8cdf7234ee54bc96_Casa-Nuno-7.jpg"
import image5 from "../../../Img/Proyectos/ProyectosResidenciales/CasaManantial/61ce0d1d362646821427e472_Casa-Nuno-6p.jpg"


function ProyectoCasaManantial() {

    const [toggler, setToggler] = useState(false);

    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });

    function openLightboxOnSlide(number) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number
        });
    }


    return (
        <>

            <div className="container col-12 py-5">
                <div className="d-flex">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/proyectos-residenciales">Residenciales</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Casa Manantial</li>
                        </ol>
                    </nav>
                </div>
                <h1 className="py-2 m-0 p-0">Casa Manantial</h1>
            </div>

            <Helmet>
                <title>Residencial - Casa Manantial</title>
            </Helmet>

            <FsLightbox
                toggler={lightboxController.toggler}

                sources={[
                    image1, image2, image3, image4, image5
                ]}

                slide={lightboxController.slide}
            />


            <div className="container position-relative">
                <img onClick={() => openLightboxOnSlide(1)} type="button" src={image1} className="w-100" alt="..." height={650} />
            </div>


            <div className="container">
                <div className="row gy-4 mt-1">

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(2)}
                            src={image2}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(3)}
                            src={image3}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(4)}
                            src={image4}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(5)}
                            src={image5}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                </div>
            </div>
        </>
    );
}

export default ProyectoCasaManantial;
