import React, { useState } from "react";
import FsLightbox from "fslightbox-react";
import "../../../App.css"
import image1 from "../../../Img/Proyectos/ProyectosIyC/OficinasIMEC/61cd23dce4f8264e17127746_Oficinas.IMEC.4.jpg"
import image2 from "../../../Img/Proyectos/ProyectosIyC/OficinasIMEC/61cd23ee870c9513981f4011_Oficinas.IMEC.1.jpg"
import image3 from "../../../Img/Proyectos/ProyectosIyC/OficinasIMEC/61cd243a564555a02ff767c3_Oficinas.IMEC.3.jpg"
import image4 from "../../../Img/Proyectos/ProyectosIyC/OficinasIMEC/61cd243afb0ea1f8e71a509c_Oficinas.IMEC.2.jpg"
import image5 from "../../../Img/Proyectos/ProyectosIyC/OficinasIMEC/61cd244b036246533fd01ddd_Oficinas.IMEC.8.jpg"
import image6 from "../../../Img/Proyectos/ProyectosIyC/OficinasIMEC/61cd244bcaa266a740b59baa_Oficinas.IMEC.9.jpg"
import image7 from "../../../Img/Proyectos/ProyectosIyC/OficinasIMEC/61cd244c90261a61a8f1ec88_Oficinas.IMEC.7.jpg"
import image8 from "../../../Img/Proyectos/ProyectosIyC/OficinasIMEC/61cd244cd2268b77b26788b9_Oficinas.IMEC.6.jpg"
import image9 from "../../../Img/Proyectos/ProyectosIyC/OficinasIMEC/61cd244de6b4853557f2996d_Oficinas.IMEC.5.jpg"

function ProyectoOIMEC() {



    const [toggler, setToggler] = useState(false);

    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });

    function openLightboxOnSlide(number) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number
        });
    }


    return (
        <>
            <FsLightbox
                toggler={lightboxController.toggler}

                sources={[
                    image1, image2, image3, image4, image5, image6, image7, image8, image9
                ]}

                slide={lightboxController.slide}
            />

            <div className="container col-12 py-5">
                <div className="d-flex">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/proyectos-industrial-y-comercial">Comercial</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Siglo XXI</li>
                        </ol>
                    </nav>
                </div>
                <h1 className="py-4 m-0 p-0">Oficinas IMEC</h1>
                <h6 className="py-2 m-0 p-0">Guadalajara, Jal. <h6>2008</h6></h6>
            </div>

            <div className="container position-relative">
                <img onClick={() => openLightboxOnSlide(1)} type="button" src={image1} className="w-100" alt="..." height={650} />
            </div>


            <div className="container">
                <div className="row gy-4 mt-1">

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(2)}
                            src={image2}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(3)}
                            src={image3}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(4)}
                            src={image4}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(5)}
                            src={image5}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(6)}
                            src={image6}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(7)}
                            src={image7}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(8)}
                            src={image8}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(9)}
                            src={image9}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                </div>
            </div>
        </>
    );
}

export default ProyectoOIMEC;
