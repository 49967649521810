import "../App.css"
import Logo from "../Img/ALFONSO-logo.svg"
import fbBlack from "../Img/icons-rss/fb-black.svg"
import igBlack from "../Img/icons-rss/ig-black.svg"
import ldBlack from "../Img/icons-rss/ld-black.svg"
import LogoGM from "../Img/logo-gm.svg"

function Footer() {
    return (
        <>
            <section className="pt-5">
                <div class="container col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mx-auto">
                    <footer>
                        <div class="row gy-4">

                            <div class="col-6 col-md-3">
                                <h6 className="fw-semibold p-0 small">Mapa del sitio</h6>
                                <ul class="nav flex-column custom-footer-list">
                                    <li class="nav-item mb-2"><a href="/" class="nav-link p-0 text-body-secondary">Inicio</a></li>
                                    <li class="nav-item mb-2"><a href="/nosotros" class="nav-link p-0 text-body-secondary">Conócenos</a></li>
                                    <li class="nav-item mb-2"><a href="/proyectos" class="nav-link p-0 text-body-secondary">Proyectos</a></li>
                                    <li class="nav-item mb-2"><a href="/blog" class="nav-link p-0 text-body-secondary">Blog</a></li>
                                    <li class="nav-item mb-2"><a href="/contacto" class="nav-link p-0 text-body-secondary">Contacto</a></li>
                                </ul>
                            </div>

                            <div class="col-6 col-md-3">
                                <h6 className="fw-semibold small">Proyectos</h6>
                                <ul class="nav flex-column custom-footer-list">
                                    <li class="nav-item mb-2"><a href="/proyectos-residenciales" class="nav-link p-0 text-body-secondary">Residencial</a></li>
                                    <li class="nav-item mb-2"><a href="/proyectos-playa-y-montana" class="nav-link p-0 text-body-secondary">Playa y Montañas</a></li>
                                    <li class="nav-item mb-2"><a href="/proyectos-hoteles" class="nav-link p-0 text-body-secondary">Hoteles</a></li>
                                    <li class="nav-item mb-2"><a href="/proyectos-comerciales" class="nav-link p-0 text-body-secondary">Comercial</a></li>
                                    <li class="nav-item mb-2"><a href="/proyectos-varios" class="nav-link p-0 text-body-secondary">Varios</a></li>
                                </ul>
                            </div>

                            <div class="col-12 col-md-3">
                                <h6 className="fw-semibold small">Contacto</h6>
                                <ul class="nav flex-column custom-footer-list">
                                    <li class="nav-item mb-2"><a href="tel:+523331002476" class="nav-link p-0 text-body-secondary">+52 33 3100 2476</a></li>
                                    <li class="nav-item mb-2"><a href="mailto:contacto@alfonsonunez.net" class="nav-link p-0 text-body-secondary">contacto@alfonsonunez.net</a></li>
                                    <li class="nav-item mb-2"><a href="https://maps.app.goo.gl/BEWgXD5kAjPYnqhv7" target="_blank" class="nav-link p-0 text-body-secondary">Amado Nervo 154
                                        Col. Ladrón de Guevara
                                        Guadalajara, Jalisco
                                        México.
                                    </a></li>
                                </ul>
                            </div>

                            <div class="col-12 col-md-3 text-center">
                                <img className="" src={Logo} width={200} alt="Logotipo"  />
                                <div className="mt-4">
                                    <a href="https://www.instagram.com/alfonso_nunez_arq/" target="_blank">
                                        <img src={igBlack} width={25} alt="Instagram" />
                                    </a>
                                    <a href="https://www.facebook.com/alfonsoarquitectos/" target="_blank">
                                    <img src={fbBlack} width={25} className="mx-3" alt="Facebook" />
                                    </a>
                                    <a href="https://www.linkedin.com/in/alfonso-n%C3%BA%C3%B1ez-arquitectos-b4a0a955/" target="_blank">
                                    <img src={ldBlack} width={25} alt="LinkedIn" />
                                    </a>
                                </div>
                            </div>

                        </div>

                        <div class="py-3 text-center border-top mt-3">
                            <small>© 2024 Alfonso Núñez Arquitectos. Todos los derechos reservados.</small>
                        </div>
                        <div class="py-3 text-center border-top">
                            <small>Powered by <a href="https://www.godspeedmedia.com/" target="_blank">
                            <img className="logo-responsive ms-2" src={LogoGM} width={150} alt="Logotipo" /></a></small>
                        </div>
                    </footer>

                </div>
            </section>
        </>
    );
}

export default Footer;
