import React, { useState } from "react";
import { Helmet } from "react-helmet";
import FsLightbox from "fslightbox-react";
import "../../../App.css"
import image1 from "../../../Img/Proyectos/ProyectosResidenciales/ProyectoLaRioja/Vista 03.jpg"
import image2 from "../../../Img/Proyectos/ProyectosResidenciales/ProyectoLaRioja/Vista 01.jpg"
import image3 from "../../../Img/Proyectos/ProyectosResidenciales/ProyectoLaRioja/Vista 02.jpg"
import image4 from "../../../Img/Proyectos/ProyectosResidenciales/ProyectoLaRioja/Vista 04.jpg"

function ProyectoLaRioja() {

        const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });

    function openLightboxOnSlide(number) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number
        });
    }


    return (
        <>

            <Helmet>
                <title>Residencial - Proyecto La Rioja</title>
            </Helmet>

            <FsLightbox
                toggler={lightboxController.toggler}

                sources={[
                    image1, image2, image3, image4
                ]}

                slide={lightboxController.slide}
            />

            <div className="container col-12 py-5">
                <div className="d-flex">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/proyectos-residenciales">Residenciales</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Proyecto La Rioja</li>
                        </ol>
                    </nav>
                </div>
                <h1 className="py-2 m-0 p-0">Proyecto La Rioja</h1>
                <h6 className="py-2 m-0 p-0">Guadalajara, Jal.<h6>2018</h6></h6>
            </div>

            <div className="container position-relative">
                <img onClick={() => openLightboxOnSlide(1)} type="button" src={image1} className="w-100" alt="..." height={650} />
            </div>


            <div className="container">
                <div className="row gy-4 mt-1">

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(2)}
                            src={image2}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(3)}
                            src={image3}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(4)}
                            src={image4}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>                    
                   
                </div>
            </div>
        </>
    );
}

export default ProyectoLaRioja;
