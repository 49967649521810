import React, { useState } from "react";
import { Helmet } from "react-helmet";
import FsLightbox from "fslightbox-react";
import "../../../App.css"
import image1 from "../../../Img/Proyectos/ProyectosCasasDescanso/CasaAlbatros/Vista 4.jpg"
import image2 from "../../../Img/Proyectos/ProyectosCasasDescanso/CasaAlbatros/Vista 1.jpg"
import image3 from "../../../Img/Proyectos/ProyectosCasasDescanso/CasaAlbatros/Vista 2.jpg"
import image4 from "../../../Img/Proyectos/ProyectosCasasDescanso/CasaAlbatros/Vista 3.jpg"
import image5 from "../../../Img/Proyectos/ProyectosCasasDescanso/CasaAlbatros/Vista 10.jpg"
import image6 from "../../../Img/Proyectos/ProyectosCasasDescanso/CasaAlbatros/Vista 11.jpg"
import image7 from "../../../Img/Proyectos/ProyectosCasasDescanso/CasaAlbatros/Vista 13.jpg"


function CasaAlbatros() {



    const [toggler, setToggler] = useState(false);

    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });

    function openLightboxOnSlide(number) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number
        });
    }


    return (
        <>
            <FsLightbox
                toggler={lightboxController.toggler}

                sources={[
                    image1, image2, image3, image4, image5, image6, image7
                ]}

                slide={lightboxController.slide}
            />

            <Helmet>
                <title>Playa y Montaña - Casa Albatros</title>
            </Helmet>

            <div className="container col-12 py-5">
                <div className="d-flex">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/proyectos-casas-de-descanso">Playa y Montaña</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Casa Albatros</li>
                        </ol>
                    </nav>
                </div>
                <h1 className="py-2 m-0 p-0">Casa Albatros</h1>
                <h6 className="py-2 m-0 p-0">Careyes, Jal.<h6>2013</h6></h6>
            </div>

            <div className="container position-relative">
                <img onClick={() => openLightboxOnSlide(1)} type="button" src={image1} className="w-100" alt="..." height={650} />
            </div>

            <div className="container">
                <div className="row gy-4 mt-1">

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(2)}
                            src={image2}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(3)}
                            src={image3}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(4)}
                            src={image4}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(5)}
                            src={image5}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(6)}
                            src={image6}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(7)}
                            src={image7}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>


                </div>
            </div>
        </>
    );
}

export default CasaAlbatros;
