import React, { useState } from "react";
import { Helmet } from "react-helmet";
import FsLightbox from "fslightbox-react";
import "../../../App.css"
import image2 from "../../../Img/Proyectos/ProyectosResidenciales/CasaMontanas/61cd3a3acaa266f612b6011c_102_0436.jpg"
import image1 from "../../../Img/Proyectos/ProyectosResidenciales/CasaMontanas/12314400_788300041292733_3548315991914547419_o.jpg"
import image3 from "../../../Img/Proyectos/ProyectosResidenciales/CasaMontanas/61cd3a6dbdaf703433ca0c8d_102_0500.jpg"
import image4 from "../../../Img/Proyectos/ProyectosResidenciales/CasaMontanas/61cd3a6e8be532fec914d08f_102_0493.jpg"
import image5 from "../../../Img/Proyectos/ProyectosResidenciales/CasaMontanas/61cd3a7150b8fe0650c61ac8_102_0462.jpg"
import image6 from "../../../Img/Proyectos/ProyectosResidenciales/CasaMontanas/61cd3a72fb0ea1609b1aa1ea_102_0479.jpg"

function ProyectoCasaMontanas() {

    const [toggler, setToggler] = useState(false);

    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });

    function openLightboxOnSlide(number) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number
        });
    }


    return (
        <>

            <Helmet>
                <title>Residencial - Casa De Las Montañas</title>
            </Helmet>

            <FsLightbox
                toggler={lightboxController.toggler}

                sources={[
                    image1, image2, image3, image4, image5, image6,
                ]}

                slide={lightboxController.slide}
            />

            <div className="container col-12 py-5">
                <div className="d-flex">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/proyectos-residenciales">Residenciales</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Casa De Las Montañas</li>
                        </ol>
                    </nav>
                </div>
                <h1 className="py-2 m-0 p-0">Casa De Las Montañas</h1>
                <h6 className="py-2 m-0 p-0">Guadalajara, Jal. <h6>2005</h6></h6>
            </div>

            <div className="container position-relative">
                <img onClick={() => openLightboxOnSlide(1)} type="button" src={image1} className="w-100" alt="..." height={650} />
            </div>


            <div className="container">
                <div className="row gy-4 mt-1">

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(2)}
                            src={image2}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(3)}
                            src={image3}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(4)}
                            src={image4}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(5)}
                            src={image5}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(6)}
                            src={image6}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>


                </div>
            </div>
        </>
    );
}

export default ProyectoCasaMontanas;
