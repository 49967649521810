import "../../../App.css"

import BTVThumbnails from "../../../Img/Proyectos/ProyectosIyC/BodegaTequilaViejo/61cd0c64e4f82614d41207b8_TequilaElViejito.BodegaMaduracion.1.jpg"
import OficinasIMECThumbnails from "../../../Img/Proyectos/ProyectosIyC/OficinasIMEC/61cd23ee870c9513981f4011_Oficinas.IMEC.1.jpg"
import G7TVThumbnails from "../../../Img/Proyectos/ProyectosIyC/G7TV/61cd283c29c4f98433c8974c_Toros.TorreVieja.4.jpg"
import AmatesThumbnails from "../../../Img/Proyectos/ProyectosIyC/Amates/20171028_105514.jpg"
import OHidalgoThumbnails from "../../../Img/Proyectos/ProyectosIyC/HidalgoOficinas/Vista 03.jpg"
import SigloXXIThumbnails from "../../../Img/Proyectos/ProyectosIyC/SigloXXI/D5_Image_20230811_105023.png"
import { Helmet } from "react-helmet"

function ProyectosIyCHome() {
    return (
        <>
            <Helmet>
                <title>Comercial</title>
            </Helmet>


            <div className="container col-12 py-5">
                <div className="d-flex">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/proyectos">Proyectos </a></li>
                            <li class="breadcrumb-item active" aria-current="page">Comercial</li>
                        </ol>
                    </nav>
                </div>
                <h1 className="mt-0">Comercial</h1>
            </div>

            <div class="container justify-content-center align-items-center">
                <div class="row gx-4 gy-4">

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-amates">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={AmatesThumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Tienda Amates</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-oficinas-imec">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={OficinasIMECThumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Oficinas IMEC</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-hidalgo-oficinas">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={OHidalgoThumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Oficinas Hidalgo</h5>
                                </div>
                            </div>
                        </a>
                    </div>


                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-siglo21">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={SigloXXIThumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Siglo XXI</h5>
                                </div>
                            </div>
                        </a>
                    </div>



                    {/*
                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-bodega-de-tequila-el-viejo">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={BTVThumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Bodega de Tequila El viejo</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-ganaderia-el-7-torre-vieja">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={G7TVThumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Ganadería "El 7" Torre Vieja</h5>
                                </div>
                            </div>
                        </a>
                    </div>
                    */}

                </div>
            </div>

        </>
    );
}

export default ProyectosIyCHome;
