import "../../../App.css"

import CampamentoTortugueroThumbnails from "../../../Img/Proyectos/ProyectosDesarrollo/CampamentoTortuguero/Sección 01.png"
import CondominioChapalaThumbnails from "../../../Img/Proyectos/ProyectosDesarrollo/DesarrolloChapala/Google 2.jpg"
import DesarrolloManzanilloThumbnails from "../../../Img/Proyectos/ProyectosDesarrollo/DesarrolloManzanillo/C.jpg"
import LaQuerenciaThumbnails from "../../../Img/Proyectos/ProyectosDesarrollo/LaQuerencia/D5_Image_20231017_145149.png"
import PescadoresThumbnails from "../../../Img/Proyectos/ProyectosDesarrollo/Pescadores/65d5078bcddc61efbc399cd8_Conjunto 03.jpg"
import SayulitaRevolucionThumbnails from "../../../Img/Proyectos/ProyectosDesarrollo/SayulitaRevolucion/65d5055b87f3383d44e686b6_CONJ 01.jpg"
import BuceriasZaragozaThumbnails from "../../../Img/Proyectos/ProyectosDesarrollo/BuceriasZaragoza/65d503b8627e7b5bc19f2d24_02.png"
import AlcocerSMAThumbnails from "../../../Img/Proyectos/ProyectosDesarrollo/AlcocerSMA/65d4fc2f93ca256a0ab3b222_D5_Image_20230504_125947.png"
import LomasAcueductoThumbnails from "../../../Img/Proyectos/ProyectosDesarrollo/LomasAcueducto/651c618bde766cbf4abe855e_a.png"
import CasaTuCasaThumbnails from "../../../Img/Proyectos/ProyectosDesarrollo/CasaTuCasa/651c47e075cc834ef59b19fa_ALFONSO NUÑEZ- EXTERIOR LATERAL POSTERIOR-05.jpg"
import G7TVThumbnails from "../../../Img/Proyectos/ProyectosIyC/G7TV/61cd283c29c4f98433c8974c_Toros.TorreVieja.4.jpg"
import SportHabitatThumbnails from "../../../Img/Proyectos/ProyectosDesarrollo/SportHabitat/lamina.jpg"
import { Helmet } from "react-helmet"

function ProyectosEnProcesoHome() {
    return (
        <>

            <Helmet>
                <title>Varios</title>
            </Helmet>

            <div className="container col-12 py-5">
                <div className="d-flex">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/proyectos">Proyectos </a></li>
                            <li class="breadcrumb-item active" aria-current="page">Varios</li>
                        </ol>
                    </nav>
                </div>
                <h1 className="mt-0">Varios</h1>
            </div>

            <div class="container justify-content-center align-items-center">
                <div class="row gx-4 gy-4">

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-campamento-tortuguero">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={CampamentoTortugueroThumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Campamento Tortuguero</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-condominio-chapala">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={CondominioChapalaThumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Condominio Chapala</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-desarrollo-manzanillo">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={DesarrolloManzanilloThumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Desarrollo Manzanillo</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-la-querencia">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={LaQuerenciaThumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">La Querencia</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-sport-habitat">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={SportHabitatThumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Condominio Sport Habitat</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-ganaderia-el-7-torre-vieja">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={G7TVThumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Ganadería "El 7" Torre Vieja</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    {/*

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-pescadores">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={PescadoresThumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Pescadores</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-sayulita-revolucion">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={SayulitaRevolucionThumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Sayulita Revolución</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-bucerias-zaragoza">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={BuceriasZaragozaThumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Bucerias Zaragoza</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-alcocer-sma">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={AlcocerSMAThumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Alcocer SMA</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-casa-lomas-acueducto">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={LomasAcueductoThumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Casa Lomas Acueducto</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-casa-tu-casa">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={CasaTuCasaThumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Casa Tu Casa</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                     */}

                </div>
            </div>

        </>
    );
}

export default ProyectosEnProcesoHome;

