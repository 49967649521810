import "../../App.css";
import { Helmet } from 'react-helmet';
import PictureArquitecturaSagradaPrincipal from "../../Img/Blogs/HogarArquitecturaSagrada/619e933166d45db733877bde_post005.webp"
import PictureArquitecturaSagrada2 from "../../Img/Blogs/HogarArquitecturaSagrada/619e933166d45db733877bde_post002.webp"
import PictureArquitecturaSagrada3 from "../../Img/Blogs/HogarArquitecturaSagrada/619e933166d45db733877bde_post003.webp"



function HogarArquitecturaSagrada() {




    return (
        <>
            <Helmet>
                <title>Blog - El Hogar: “Arquitectura Sagrada”</title>
                <meta property="og:title" content="Guía para contratar servicios arquitectónicos" />
                <meta property="og:description" content="Aprende cómo contratar los mejores servicios arquitectónicos con nuestra guía completa. Descubre qué preguntar, cómo seleccionar un arquitecto y más. ¡Lee ahora!" />
                <meta property="og:url" content="https://demo-alfredo.godspeedcheckout.com" />
                <meta property="og:image" content="../../../public/Blogs/Guia-Arquitecto/arquitecto-sonriente-casco_23-2147702525.jpg" />
                <meta property="og:type" content="article" />
            </Helmet>

            <div className="container col-12 col-sm-12 col-md-12 col-lg-10 col-xl-7 col-xxl-8 py-4">
                <div className="col-12">
                    <div className="d-flex">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/blog">Blog</a></li>
                                <li class="breadcrumb-item active" aria-current="page">El Hogar: “Arquitectura Sagrada”</li>
                            </ol>
                        </nav>
                    </div>
                    <h1 className="py-2 m-0 p-0">El Hogar: “Arquitectura Sagrada”</h1>
                    <h6 class="fw-light mt-3">23 de marzo, 2021</h6>
                </div>
            </div>

            <div className="container col-12 col-sm-12 col-md-12 col-lg-10 col-xl-7 col-xxl-8 mx-auto">
                <img src={PictureArquitecturaSagradaPrincipal} className="w-100 my-4" alt="Imagen Blog" />
            </div>

            <div className="container col-12 col-sm-12 col-md-12 col-lg-10 col-xl-7 col-xxl-5 mx-auto mt-4">
                <h5 className="lh-base fw-light">
                    La casa -el hogar- es un espacio fundamental para toda persona, fundamental para el funcionamiento de la sociedad.
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    Originalmente los hombres buscaron lugares de refugio -en un principio posiblemente una cueva- en el que se sintieran protegidos, en donde resguardaran a su familia; almacenar víveres, guardar sus instrumentos de caza y de trabajo; en dónde se protegiera el fuego que los calienta y alimenta.
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    Cuando hablamos de arquitectura sagrada* generalmente nos referimos a aquellos espacios dedicados al culto religioso, a ese lugar en donde buscamos y deseamos encontramos con la divinidad. Pero también podríamos hablar de lo “sagrada” que es nuestra familia…; la familia es sagrada por lo que es y representa: es sagrada nuestra vida, sagrado nuestro cónyuge, nuestros hijos, nuestro amor ahí vivido…; un hogar adecuado nos lleva a vivir lo sagrado de nosotros mismos y de nuestras relaciones primeras. Resguardar todo lo digno –como digno es el hombre-, requiere un espacio adecuado: de un hogar, de un espacio sagrado.
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    (*Sagrado:” Sagrado es lo que se considera digno de veneración u objeto de culto por atribuírsele un carácter divino o una relación con la divinidad o las fuerzas sobrenaturales. Por extensión, también se denomina “sagrado” a cualquier cosa digna de veneración y respeto o de una importancia tal que se considera irrenunciable.” (RAE))
                </h5>

                <h5 className="lh-base mt-4 fw-light">
                Pero el “hogar” está ubicado en un lugar específico, está formado por muros, espacios delimitados y concretos… Para el arquitecto, el reto de diseñar una casa –un hogar- es mucho más que definir cuatro paredes en donde ubicar muebles y funciones (o la estética de una buena fotografía…): la Arquitectura responde a necesidades espaciales y psicológicas, a relaciones personales; es vinculación de espacios, circulaciones, dimensiones, proporción, texturas, relación con el entorno…: debe ser un “guante hecho a la medida” de las necesidades particulares de la familia que la habitará, un espacio que nos ennoblezca…
                </h5>

                <img src={PictureArquitecturaSagrada2} className="w-100 mt-4" alt="Imagen Blog" />

                <h5 className="lh-base fw-light mt-4">
                    Desde luego que existen infinidad de opciones para proyectar y resolver una casa específica para una familia: tantas formas como arquitectos… La responsabilidad del arquitecto en cuestión deberá contar -para dar una solución adecuada- no solo las necesidades físicas, sino también las sicológicas, espirituales, culturales de quien la habitará; así como es obligado tomar en cuenta el entorno físico donde se ubicará: ubicación, topografía, clima, contexto comunicaciones…
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    He notado con frecuencia arquitectos que ponen el énfasis de sus proyectos en los aspectos estéticos y pasan muy de prisa en los aspectos funcionales, lógicos y sicológicos necesarios. Posiblemente éste sea un ejemplo rupestre: todos tenemos variedad de zapatos, pero siempre hay un par que son especialmente cómodos y nos olvidamos de que los tenemos puestos…; así la casa: una casa adecuada es aquella en la que todo funciona bien sin darnos cuenta y nos facilita la vida. Posiblemente no sepamos por qué nos gusta habitarla, pero nos sentimos cómodos y seguros en ella. También tendremos zapatos con los que “convivimos” todo el día, pero en el momento que nos los quitamos somos conscientes que estamos mucho más cómodos sin ellos…; una casa puede ser así.
                </h5>
                
                <h5 className="lh-base fw-light mt-4">
                    Por ésto comentaba en otra ocasión que la arquitectura es algo serio y requiere soluciones serias: la buena arquitectura influye en nosotros, en nuestro comportamiento y en la relación con quienes la compartimos, es escuela de vida para nosotros y nuestros hijos. Y la mala arquitectura también influye…
                </h5>
               
                <img src={PictureArquitecturaSagrada3} className="w-100 my-4" alt="Imagen Blog" />

                <h5 className="lh-base fw-light mt-4">
                    Me encontré esta cita interesante de Ivan Illich; me abre un panorama interesante cuando dice:<i> “Las bestias tienen madrigueras; el ganado establos; los autos se guardan en cobertizos, y para los coches hay cocheras. Solo los seres humanos pueden habitar. Habitar es un arte. Únicamente los seres humanos aprenden a habitar. La casa no es una madriguera ni una cochera.”</i>
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    Un hogar no solo es un espacio habitable, sino que debe ser habitable de una manera adecuada: de una manera humana; adecuada para la vida del hombre con todas sus complejidades.
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    Me he encontrado con los años con muchos concursos de arquitectura sobre vivienda y he participado en más de uno. Desde mi experiencia ha sido un tanto decepcionante observar el veredicto final: en muchas ocasiones se toman en cuenta las fotografías de la espectacular fachada; el “equilibrio de la volumetría”; el juego de sombras; la innovadora solución estructural; el uso de materiales exóticos; el detalle de sus acabados… Pienso que sería interesante que estos concursos sean sobre espacios habitados por algún tiempo, e incluir algún cuestionario a sus habitantes sobre como fomenta y colabora la arquitectura en la vida y relaciones familiares… Con estas respuestas posiblemente se podría enriquecer la decisión a la hora de premiar la mejor arquitectura…
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    Así, la bondad de una casa –el ser de la Arquitectura- no están en la “creatividad del arquitecto”, sino por el respeto a sus usuarios.
                </h5>                

                <h6 className="fw-light mt-4">
                    — Arq. Alfonso Núñez.
                </h6>

            </div>
        </>
    );
}

export default HogarArquitecturaSagrada;
