import React, { useState } from "react";
import FsLightbox from "fslightbox-react";
import "../../../App.css"
import image1 from "../../../Img/Proyectos/ProyectosTurismo/HotelCasaColula/61cd2e1939d4b2b5db4024b7_1.jpg"
import image2 from "../../../Img/Proyectos/ProyectosTurismo/HotelCasaColula/61cd2e46ec15b50310e3fc1c_2.jpg"
import image3 from "../../../Img/Proyectos/ProyectosTurismo/HotelCasaColula/61cd2e662486cda2d4595a82_8.jpg"
import image4 from "../../../Img/Proyectos/ProyectosTurismo/HotelCasaColula/61cd2e8b2ef0ae0b0dfbae0b_4.jpg"
import image5 from "../../../Img/Proyectos/ProyectosTurismo/HotelCasaColula/61cd2e8b90261adcabf20804_3.jpg"
import image6 from "../../../Img/Proyectos/ProyectosTurismo/HotelCasaColula/61cd2e8be4f826e26112aa59_5.jpg"
import image7 from "../../../Img/Proyectos/ProyectosTurismo/HotelCasaColula/61cd2eb5baf02f32e09c761e_7.jpg"
import image8 from "../../../Img/Proyectos/ProyectosTurismo/HotelCasaColula/61cd2eb5c90e800a4d2f66cf_6.jpg"
import { Helmet } from "react-helmet";

function ProyectoHotelCC() {



    const [toggler, setToggler] = useState(false);

    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });

    function openLightboxOnSlide(number) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number
        });
    }


    return (
        <>
            <FsLightbox
                toggler={lightboxController.toggler}

                sources={[
                    image1, image2, image3, image4, image5, image6, image7, image8
                ]}

                slide={lightboxController.slide}
            />

            <Helmet>
                <title>Hoteles - Hotel Casa Cocula</title>
            </Helmet>

            <div className="container col-12 py-5">
                <div className="d-flex">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/proyectos-turismo">Hoteles</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Hotel Casa Cocula</li>
                        </ol>
                    </nav>
                </div>
                <h1 className="py-2 m-0 p-0">Hotel Casa Cocula</h1>
                <h6 className="py-2 m-0 p-0">Cocula, Jal.<h6>2014</h6></h6>
            </div>

            <div className="container position-relative">
                <img onClick={() => openLightboxOnSlide(1)} type="button" src={image1} className="w-100" alt="..." height={650} />
            </div>

            <div className="container col-12 col-sm-12 col-md-12 col-lg-10 col-xl-8 col-xxl-5 mx-auto pb-4">
                <h1 className="py-4 text-center m-0 p-0">Hotel Casa Cocula</h1>
            </div>

            <div className="container position-relative">
                <img onClick={() => openLightboxOnSlide(1)} type="button" src={image1} className="w-100" alt="..." height={650} />
            </div>


            <div className="container">
                <div className="row gy-4 mt-1">

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(2)}
                            src={image2}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(3)}
                            src={image3}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(4)}
                            src={image4}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(5)}
                            src={image5}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(6)}
                            src={image6}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(7)}
                            src={image7}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(8)}
                            src={image8}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                </div>
            </div>
        </>
    );
}

export default ProyectoHotelCC;
