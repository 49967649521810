import "../App.css"
import React, { useEffect, useState } from 'react';

import Logo from "../Img/ALFONSO-logo.svg"





const ModalNewsletter = () => {



    useEffect(() => {
        const timer = setTimeout(() => {
            const modal = new window.bootstrap.Modal(document.getElementById('exampleModal'));
            modal.show();
        }, 3000);

        return () => clearTimeout(timer); // Limpiar el temporizador cuando el componente se desmonte
    }, []);


    const [formData, setFormData] = useState({
        nombre: '',
        email: ''
    });

    const [isFormVisible, setFormVisible] = useState(true);

    const hideForm = () => {
        setFormVisible(false);
    };

    const [confirmationMessage, setConfirmationMessage] = useState('');




    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();



        try {
            const response = await fetch('https://godspeedcheckout.com/mailchimp_api_alfonso.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                // Maneja la respuesta exitosa
                //console.log('Form successfully submitted');
                setConfirmationMessage('Te has suscrito con éxito. ¡Gracias!');
                setFormData({ nombre: '', email: '' }); // Limpiar el formulario
                hideForm();

            } else {
                // Maneja los errores de respuesta
                console.error('Error submitting form');
            }
        } catch (error) {
            // Maneja los errores de la solicitud
            console.error('Error:', error);
        }
    };






    return (
        <>
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content border-0 rounded-0">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="exampleModalLabel">
                                <img className="logo-responsive" src={Logo} width={150} alt="Logotipo" />
                            </h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <h2 className=" text-center fw-normal lh-1 h2">Suscríbete a nuestro newsletter</h2>
                            <h5 className=" text-center fw-light mt-4 opacity-75 col-12 col-md-10 mx-auto">Y obtén información relevante sobre arquitectura y diseño.</h5>
                            {isFormVisible && (
                                <form onSubmit={handleSubmit}>
                                    <div class="mt-4">
                                        <input type="text" id="nombre" name="nombre" class="form-control" value={formData.nombre} onChange={handleChange} placeholder="Nombre" required />
                                    </div>
                                    <div class="mt-2">
                                        <input type="email" id="email" name="email" class="form-control" value={formData.email} onChange={handleChange} placeholder="Correo electrónico" required />
                                    </div>

                                    <div class="d-grid gap-2 col mx-auto mt-4">
                                        <button type="submit" class="btn btn-primary btn-lg">Suscribirme</button>
                                    </div>
                                </form>
                            )}
                            {confirmationMessage && <p class="mt-4"><div class="alert alert-success rounded-0" role="alert"><i class="bi bi-check-circle-fill me-2"></i> {confirmationMessage}</div></p>}
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default ModalNewsletter;
