import "../Side.css"
import Logo from "../Img/ALFONSO-logo.svg"

function BlogTameplate() {
    return (
        <>

            
        </>
    );
}

export default BlogTameplate;
