import React, { useState } from "react";
import FsLightbox from "fslightbox-react";
import "../../../App.css"
import image1 from "../../../Img/Proyectos/ProyectosIyC/BodegaTequilaViejo/61ccf3616c8c4a25dd68528a_TequilaElViejito.BodegaMaduracion.6.jpg"
import image2 from "../../../Img/Proyectos/ProyectosIyC/BodegaTequilaViejo/61cd0c4227734bc102c250fa_TequilaElViejito.BodegaMaduracion.5.jpg"
import image3 from "../../../Img/Proyectos/ProyectosIyC/BodegaTequilaViejo/61cd0c638cdf7258ba4fb108_TequilaElViejito.BodegaMaduracion.4.jpg"
import image4 from "../../../Img/Proyectos/ProyectosIyC/BodegaTequilaViejo/61cd0c6490261a538cf186fe_TequilaElViejito.BodegaMaduracion.2.jpg"
import image5 from "../../../Img/Proyectos/ProyectosIyC/BodegaTequilaViejo/61cd0c64c237ed6981d987e8_TequilaElViejito.BodegaMaduracion.3.jpg"
import image6 from "../../../Img/Proyectos/ProyectosIyC/BodegaTequilaViejo/61cd0c64e4f82614d41207b8_TequilaElViejito.BodegaMaduracion.1.jpg"
import image7 from "../../../Img/Proyectos/ProyectosIyC/BodegaTequilaViejo/61cd0c6b9527646c70440485_TequilaElViejito.BodegaMaduracion.7.jpg"

function ProyectoBTV() {



    const [toggler, setToggler] = useState(false);

    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });

    function openLightboxOnSlide(number) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number
        });
    }


    return (
        <>
            <FsLightbox
                toggler={lightboxController.toggler}

                sources={[
                    image1, image2, image3, image4, image5, image6, image7
                ]}

                slide={lightboxController.slide}
            />


            <div className="container col-12 py-5">
                <div className="d-flex">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/proyectos-industrial-y-comercial">Comercial</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Bodega de Tequila "El viejo"</li>
                        </ol>
                    </nav>
                </div>
                <h1 className="py-2 m-0 p-0">Bodega de Tequila "El viejo"</h1>                
            </div>

            <div className="container position-relative">
                <img onClick={() => openLightboxOnSlide(1)} type="button" src={image1} className="w-100" alt="..." height={650} />
            </div>


            <div className="container">
                <div className="row gy-4 mt-1">

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(2)}
                            src={image2}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(3)}
                            src={image3}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(4)}
                            src={image4}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(5)}
                            src={image5}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(6)}
                            src={image6}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(7)}
                            src={image7}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                </div>
            </div>
        </>
    );
}

export default ProyectoBTV;
