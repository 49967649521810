import React from 'react';
import "../App.css"
import Formulario from "../Components/Formulario";

function Contacto() {
    return (
        <>

            <section className="bg-wallpaper-contacto">
                <div class="container col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4 mx-auto">
                    <div class="row gy-5">

                        <div class="col-12 col-md-12 text-center">
                            <h2 className="fw-medium text-center mb-5 mt-3">Contáctanos.</h2>
                            <div className="mt-4">
                                <h6 className="lh-base fw-light">
                                    <a href="tel:+523331002476"> (+52) 33 3100 2476 </a>
                                </h6>
                                <h6 className="lh-base fw-light">
                                    <a href="mailto:contacto@alfonsonunez.net">
                                        contacto@alfonsonunez.net
                                    </a>
                                </h6>
                                <h6 className="lh-base fw-light">
                                    <a href="https://maps.app.goo.gl/BEWgXD5kAjPYnqhv7" target="_blank">
                                        Amado Nervo 154 Col. Ladrón de Guevara.<br />
                                        Guadalajara, Jalisco. México.
                                    </a>
                                </h6>
                            </div>
                        </div>

                        <div className='row text-center mt-5 gx-2 align-items-center'>
                            <div className='col-5'><hr /></div>
                            <div className='col-2 opacity-25'>O</div>
                            <div className='col-5'><hr /></div>

                        </div>

                        <div class="col-12 col-md-12">
                            <h2 className="fw-medium text-center mb-5">Déjanos tus datos. <br />Te contactaremos a la brevedad.</h2>
                            <Formulario />
                        </div>
                    </div>
                </div>
            </section>

            <section className="mt-5">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14931.017967589689!2d-103.37463!3d20.6795649!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428af13944d7913%3A0x4a0cce6f077a7ff8!2sArquitecto%20Alfonso%20Nu%C3%B1ez!5e0!3m2!1ses-419!2smx!4v1719448370939!5m2!1ses-419!2smx" width="100%" height="450" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </section>
        </>
    );
}

export default Contacto;
