import React, { useState } from "react";
import { Helmet } from "react-helmet";
import FsLightbox from "fslightbox-react";
import "../../../App.css"
import image3 from "../../../Img/Proyectos/ProyectosCasasDescanso/CasaE/61ce190a8bd4f59b347a6405_LaReserva.CE.1.jpg"
import image2 from "../../../Img/Proyectos/ProyectosCasasDescanso/CasaE/61ce1705f9e0ca01d589677e_LaReserva.CE.2.jpg"
import image1 from "../../../Img/Proyectos/ProyectosCasasDescanso/CasaE/61ce192c5290506bdefc9bd3_LaReserva.CE.3.jpg"
import image4 from "../../../Img/Proyectos/ProyectosCasasDescanso/CasaE/61ce192b053ef680500aa7f4_LaReserva.CE.4.jpg"
import image5 from "../../../Img/Proyectos/ProyectosCasasDescanso/CasaE/61ce192acaa26681dbbaa397_LaReserva.CE.5.jpg"
import image6 from "../../../Img/Proyectos/ProyectosCasasDescanso/CasaE/61ce192c3bb754ed0485f460_LaReserva.CE.6.jpg"
import image7 from "../../../Img/Proyectos/ProyectosCasasDescanso/CasaE/61ce192b002f4d02e9ac6943_LaReserva.CE.7.jpg"



function ProyectoCasaE() {

    const [toggler, setToggler] = useState(false);

    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });

    function openLightboxOnSlide(number) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number
        });
    }


    return (
        <>

            <Helmet>
                <title>Proyecto Casas de Descanso - Casa E, Chacala</title>
            </Helmet>

            <FsLightbox
                toggler={lightboxController.toggler}

                sources={[
                    image1, image2, image3, image4, image5, image6, image7
                ]}

                slide={lightboxController.slide}
            />

            <div className="container col-12 col-sm-12 col-md-12 col-lg-10 col-xl-8 col-xxl-5 mx-auto pb-4">
                <h1 className="py-4 text-center m-0 p-0">Casa E, Chacala</h1>
            </div>

            <div className="container position-relative">
                <img onClick={() => openLightboxOnSlide(1)} type="button" src={image1} className="w-100" alt="..." height={650} />
            </div>


            <div className="container">
                <div className="row gy-4 mt-1">

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(2)}
                            src={image2}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(3)}
                            src={image3}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(4)}
                            src={image4}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(5)}
                            src={image5}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    
                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(6)}
                            src={image6}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    
                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(7)}
                            src={image7}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                </div>
            </div>
        </>
    );
}

export default ProyectoCasaE;
