import React, { useRef } from 'react';
import "../../App.css";
import PictureArqGuia from "../../Img/Blogs/Guia-Arquitecto/adult-architect-blueprint-416405-1-e1558802628675.webp";
import PictureAcabadosPrincipal from "../../Img/Blogs/AcabadosDeLujo/1920_bantildeo.jpg";
import PictureArquitectoArtLujoPrincipal from "../../Img/Blogs/ArquitectoArtLujo/61ce8a4065c416473ec830ff_bg_casa.webp";
import PictureArquitecturaSagradaPrincipal from "../../Img/Blogs/HogarArquitecturaSagrada/606dc84b2a3044aa55e044cf_1.png";
import PictureArquitecturaInventarDescubrirPrincipal from "../../Img/Blogs/ArquitecturaInventarDescubrir/61ce8847be32b1b5fbef299a_blog3.webp";
import PictureObjetivoArquitecturaPrincipal from "../../Img/Blogs/ObjetivoArquitectura/seguridad-hogar-vacaciones-1200x800.jpg";
import { Helmet } from "react-helmet";

function Blog() {
    const containerRef = useRef(null);

    const scrollLeft = () => {
        if (containerRef.current) {
            containerRef.current.scrollBy({ left: -300, behavior: 'smooth' });
        }
    };

    const scrollRight = () => {
        if (containerRef.current) {
            containerRef.current.scrollBy({ left: 300, behavior: 'smooth' });
        }
    };

    return (
        <>
            <Helmet>
                <title>Blog - Alfonso Nuñez Arquitectos</title>
            </Helmet>

            <section className="container pt-3 pb-5 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-7 mx-auto">
                <div className="card-container" ref={containerRef}>
                    {/* Tu código de tarjetas aquí */}
                    <div className="card mb-5">
                        <a href="/guia-para-contratar-servicios-arquitectonicos">
                            <div className="row align-items-center">
                                <div className="col-md-6">
                                    <img src={PictureArqGuia} className="img-blog" alt="..." />
                                </div>
                                <div className="col-md-6">
                                    <div className="card-body">
                                        <h6 className="fw-light">11 de agosto, 2019</h6>
                                        <h2 className="fw-medium my-4">Guía para contratar servicios arquitectónicos</h2>
                                        <h6 className="fw-light">Copyright 1989. The American Institute of Architects. <br />
                                            Traducción y adaptación: Ámbito3, Arther y Alfonso Núñez-Arquitectos.</h6>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="card mb-5">
                        <a href="/guia-para-contratar-servicios-arquitectonicos">
                            <div className="row align-items-center">
                                <div className="col-md-6">
                                    <img src={PictureArqGuia} className="img-blog" alt="..." />
                                </div>
                                <div className="col-md-6">
                                    <div className="card-body">
                                        <h6 className="fw-light">11 de agosto, 2019</h6>
                                        <h2 className="fw-medium my-4">Guía para contratar servicios arquitectónicos</h2>
                                        <h6 className="fw-light">Copyright 1989. The American Institute of Architects. <br />
                                            Traducción y adaptación: Ámbito3, Arther y Alfonso Núñez-Arquitectos.</h6>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="card mb-5">
                        <a href="/guia-para-contratar-servicios-arquitectonicos">
                            <div className="row align-items-center">
                                <div className="col-md-6">
                                    <img src={PictureArqGuia} className="img-blog" alt="..." />
                                </div>
                                <div className="col-md-6">
                                    <div className="card-body">
                                        <h6 className="fw-light">11 de agosto, 2019</h6>
                                        <h2 className="fw-medium my-4">Guía para contratar servicios arquitectónicos</h2>
                                        <h6 className="fw-light">Copyright 1989. The American Institute of Architects. <br />
                                            Traducción y adaptación: Ámbito3, Arther y Alfonso Núñez-Arquitectos.</h6>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    
                    {/* Repite el código de las tarjetas */}
                </div>
                <button className="scroll-button left" onClick={scrollLeft}>◄</button>
                <button className="scroll-button right" onClick={scrollRight}>►</button>
            </section>
        </>
    );
}

export default Blog;
