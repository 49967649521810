import "../../App.css";
import { Helmet } from 'react-helmet';
import PictureArqGuia from "../../Img/Blogs/Guia-Arquitecto/adult-architect-blueprint-416405-1-e1558802628675.webp"

function BlogTameplate() {




    return (
        <>
            <Helmet>
                <title>Blog - Guía para contratar servicios arquitectónicos</title>
                <meta property="og:title" content="Guía para contratar servicios arquitectónicos" />
                <meta property="og:description" content="Aprende cómo contratar los mejores servicios arquitectónicos con nuestra guía completa. Descubre qué preguntar, cómo seleccionar un arquitecto y más. ¡Lee ahora!" />
                <meta property="og:url" content="https://demo-alfredo.godspeedcheckout.com" />                
                <meta property="og:image" content="../../../public/Blogs/Guia-Arquitecto/arquitecto-sonriente-casco_23-2147702525.jpg"/>
                <meta property="og:type" content="article" />
            </Helmet>
            
            <div className="container col-12 col-sm-12 col-md-12 col-lg-10 col-xl-7 col-xxl-8 py-4">
                <div className="col-12">
                    <div className="d-flex">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/blog">Blog</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Guía para contratar servicios arquitectónicos</li>
                            </ol>
                        </nav>
                    </div>
                    <h1 className="py-2 m-0 p-0">Guía para contratar servicios arquitectónicos</h1>
                    <h6 class="fw-light mt-3">11 de agosto, 2019</h6>
                </div>
            </div>

            <div className="container col-12 col-sm-12 col-md-12 col-lg-10 col-xl-7 col-xxl-8 mx-auto">
                <img src={PictureArqGuia} className="w-100 my-4" />
            </div>

            <div className="container col-12 col-sm-12 col-md-12 col-lg-10 col-xl-7 col-xxl-5 mx-auto mt-4">
                <h5 className="lh-base fw-light">
                    Algunas personas no se dan cuenta de lo complicado que es construir,
                    hasta que se encuentran perdidos en el laberinto de las opciones de
                    diseño, reglamentos, restricciones y leyes de construcción,
                    contratistas y así sucesivamente. No hay dos proyectos de edificios
                    que sean exactamente iguales, por lo tanto no hay un simple patrón a
                    seguir.
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    Construir requiere una gran inversión económica; es probable que usted
                    valla a invertir su patrimonio, y es de elemental sensatez que se
                    asesore{" "}
                    <i>
                        — como lo haría con un médico por un problema de salud, o un abogado
                        por un asunto legal —
                    </i>{" "}
                    para que el resultado arquitectónico sea el correcto tanto en lo
                    económico como en el aspecto funcional y estético.
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    El Arquitecto es un profesional que tiene la educación, capacitación,
                    experiencia y visión, para guiar por el proceso de diseño y
                    construcción, ayudando a definir qué es lo que se quiere construir,
                    logrando obtener mayores beneficios por su inversión.
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    Los arquitectos ven el panorama completo. No solo diseñan cuatro
                    paredes y un techo: ellos crean un ambiente total -interior y
                    exterior-, que satisface las necesidades funcionales, creando espacios
                    dinámicos y expresivos en los cuales trabajar o vivir.
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    Sea que este remodelando, ampliando o construyendo de la nada, el
                    arquitecto puede guiar el camino. Trabajando con contratistas y otros
                    profesionales de la construcción, los arquitectos pueden desarrollar
                    un proyecto que resuelva sus necesidades cumpliendo con sus objetivos
                    de tiempo y costo.
                </h5>

                <h3 className="py-4 fw-semibold">El Arquitecto resuelve problemas</h3>

                <h5 className="lh-base fw-light">
                    La mayoría de los edificios comienzan con un deseo o una necesidad:
                    <i> “Necesito más espacio para archivo en mi oficina”, ó, “la casa nos
                        queda chica”.</i>
                </h5>

                <h5 className="lh-base mt-4 ">
                    <i> Pero, ¿cómo pasar esa necesidad o deseo a metros cuadrados y espacios tridimensionaless</i>
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    Eso es lo que los arquitectos están capacitados para hacer: resuelven problemas de manera creativa. Con su amplio conocimiento del diseño y la construcción, los arquitectos pueden mostrarle alternativas y opciones que no las podría imaginar uno mismo.
                </h5>


                <h5 className="lh-base fw-light mt-4">
                    Eso es lo que los arquitectos están capacitados para hacer: resuelven problemas de manera creativa. Con su amplio conocimiento del diseño y la construcción, los arquitectos pueden mostrarle alternativas y opciones que no las podría imaginar uno mismo.
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    ¿Necesita más espacio para su familia que está creciendo?; un arquitecto puede mostrarle como hacer más grande su casa sin tener que mudarse. ¿No está seguro de que tan rápido su negocio vaya a crecer?; un arquitecto puede diseñar una oficina que resuelva sus necesidades hoy y pueda adaptarse mañana. ¿Tiene un presupuesto limitado?; el arquitecto busca maneras de hacer su proyecto cuidando costos.
                </h5>

                <h3 className="py-4 fw-semibold">El Arquitecto puede ahorrarle dinero</h3>

                <h5 className="lh-base fw-light mt-4">
                    Los servicios del arquitecto son una inversión inteligente para su dinero, no un gasto agregado a su proyecto: <i>¿Por qué?:</i>
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    <i>— Porque</i> un proyecto bien concebido se puede construir más eficaz y económicamente. Los arquitectos definen su proyecto trabajando con usted; conforme éste avanza, se pueden realizar cambios en el papel, lo cual resulta mucho menos costoso que realizarlos después, cuando la construcción está en ejecución. Por medio de los planos proporcionados por el arquitecto se hace más fácil para el contratista precisar el costo y construir su proyecto.
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    <i>— Porque</i> edificios con uso eficiente de energía pueden ahorrarle dinero en la operación diaria. Un arquitecto puede diseñar un edificio para aprovechar el calor del sol, la entrada de luz natural y la ventilación; creando espacios confortables y reduciendo así el consumo de energía por calefacción, aire acondicionado e iluminación.
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    <i>— Porque</i> el arquitecto puede trabajar con su presupuesto y ayudarlo a seleccionar los materiales apropiados y mano de obra a un precio justo. Los arquitectos desarrollan los planos y especificaciones para ayudarlo a conseguir mejores alternativas de construcción basadas en sus requerimientos.
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    <i>— Porque</i> un arquitecto puede ayudarlo a escoger los materiales y acabados que sean durables además de bellos, economizando en mantenimiento frecuente y costos de reemplazo. Los arquitectos trabajan y se mantienen al corriente de los avances de tecnología en cubiertas, muros, pisos, pintura, acabados, etc. Su familiaridad con el alto rango de materiales lo habilita para sugerir los materiales más apropiados para su proyecto.
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    <i>— Porque</i> un buen diseño vende; una casa con un buen diseño tiene una tasa de reventa más alta. Una tienda bien diseñada atrae clientes. Un ambiente bien diseñado para trabajar aumenta la productividad.
                </h5>

                <h3 className="py-4 fw-semibold">El arquitecto puede facilitarle la vida</h3>

                <h5 className="lh-base fw-light">
                    Enfrentémoslo: construir es un proceso largo que a menudo puede resultar algo desordenado y conflictivo, particularmente si está viviendo o trabajando en el espacio que está en construcción. El arquitecto que usted contrata ve por sus intereses y trata de encontrar maneras para facilitar el proceso.
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    Si su proyecto requiere ingeniería u otros servicios de diseño, el arquitecto puede coordinar a este equipo de expertos por usted. El arquitecto conoce los complejos reglamentos y restricciones para edificar. El arquitecto puede ayudarlo a encontrar contratistas de la construcción calificados, basándose en sus requerimientos. El arquitecto visita el lugar de la construcción y ayuda a verificar que el proyecto se esté construyendo de acuerdo a los planos y especificaciones.
                </h5>

                <h3 className="py-4 fw-semibold">¿Cómo encuentra el arquitecto correcto?</h3>

                <h5 className="lh-base fw-light">
                    Cada arquitecto tiene su propio estilo, aproximación al diseño y métodos de trabajo. Entonces es importante encontrar un arquitecto que entienda su estilo y necesidades. Si usted ya ha trabajado con un arquitecto en particular y se sintió cómodo, tiene sentido que lo llame de nuevo. De no ser así, tendrá usted que esforzarse un poco.
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    Es probable que tenga algún pariente o amistad que sea arquitecto; investigue si tiene la especialización y experiencia en el tipo de proyecto que usted necesita y piense en él como una opción más. No necesariamente es la persona más adecuada para desarrollar su proyecto en particular y darle el servicio que usted desea.
                </h5>

                <h3 className="py-4 fw-semibold">Una tarea pequeña para un camino largo</h3>

                <h5 className="lh-base fw-light">
                    Primero piense cuidadosamente en las necesidades y metas de su edificio: ¿De cuánto dinero dispone?, ¿Necesita más espacio?, ¿Qué actividades se alojarán en ese espacio?, ¿Cómo va a financiarlo?, ¿Dónde estará localizado?, ¿Está planeando hacer parte del trabajo usted mismo? No se preocupe si no tiene todas las respuestas. De ser necesario el arquitecto puede ayudarlo a aclarar sus dudas.
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    Comience por hacer una lista de los posibles arquitectos. Busque quien ha diseñado proyectos en su comunidad que le gusten. Obtenga recomendaciones de amigos, parientes o conocidos que hayan trabajado con arquitectos. Verifique que el arquitecto sea miembro del colegio de arquitectos local. Ser miembro de una institución significa que el arquitecto esta adherido a un código profesional de ética y tiene acceso a una variedad de recursos técnicos profesionales. Como en cualquier actividad, es importante su ética profesional que le brindara seriedad y compromiso por su trabajo.
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    Llame a aquellos que considere le puedan ayudar. Describa su proyecto y pregunte si están disponibles para tomarlo. Si lo están, pida información acerca del perfil de la firma, cualidades y experiencia. Si la oficina es incapaz de manejar su proyecto, pregunte si le pueden sugerir otra empresa. El material que usted reciba de las empresas interesadas puede incluir una carta de interés, folletos, fotos de trabajos anteriores o material biográfico de personal importante. Determine cual firma tiene la experiencia y habilidades para realizar su proyecto y así reduzca su lista a dos o tres arquitectos con los que posteriormente podrá entrevistarse.
                </h5>

                <h3 className="py-4 fw-semibold">Empatía</h3>

                <h5 className="lh-base fw-light">
                    La entrevista es crucial porque le da una oportunidad de conocer a la gente que podría diseñar su proyecto y ver si existe un buen entendimiento. Recuerde que usted estará trabajando con el arquitecto por un largo tiempo, por lo cual es importante que sea alguien con quien se sienta cómodo.
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    Dele por lo menos una hora a la entrevista. La reunión puede ser en la oficina del arquitecto <i>— útil porque podrá ver donde se realizara el trabajo—</i>, o la entrevista podría ser en su casa u oficina <i>—útil porque el arquitecto pude aprender más sobre su proyecto y necesidades—</i>; como lo sienta mejor. El arquitecto podría enseñarle diapositivas o fotografías de trabajos anteriores y describir como la experiencia de la empresa y sus especialistas lo ayudaran. Algunos arquitectos no cobran por la entrevista, pero algunos otros si lo hacen. Antes de la entrevista, pregunte si hay algún honorario.
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    Durante la entrevista, haga preguntas. <i>¿Qué tan ocupada esta la empresa?</i>, <i>¿Quién se encargara del trabajo?</i> (insista en conocer a las personas que diseñaran realmente su proyecto), <i>¿Cuál es la filosofía de diseño de la empresa?</i>, <i>¿Cómo el arquitecto abordara su proyecto?</i>, <i>¿Qué tan interesada está la empresa en hacer su trabajo?</i> Hable acerca de su presupuesto y averigüe acerca del rango de los honorarios que el arquitecto anticiparía por su proyecto. Antes de hacer una elección final, haga que el arquitecto le lleve a ver un proyecto terminado. Es apropiado preguntar al arquitecto por referencias de cliente anteriores. Estas referencias son valiosas.
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    Si, durante el curso de la entrevista, hay algo que usted no entienda, pregunte al arquitecto para aclararlo. Si se siente intimidado o el arquitecto no explica las cosas de una manera que usted las pueda entender, entonces no son los indicados para usted.
                </h5>

                <h3 className="py-4 fw-semibold">Haciendo la elección final</h3>

                <h5 className="lh-base fw-light">
                    Finalmente escogerá el arquitecto en quien confíe y sienta que es bueno para su proyecto. No es como comprar un auto, y ver el producto final y probarlo. El arquitecto proporciona servicios profesionales, no un producto terminado. El arquitecto indicado será el que pueda proporcionar buen juicio, especialización técnica y habilidades creativas a un precio razonable, para ayudarlo a encontrar un proyecto que sea acorde con sus necesidades prácticas así como a sus sueños.
                </h5>

                <h3 className="py-4 fw-semibold">Acerca de como se les paga a los arquitectos</h3>

                <h5 className="lh-base fw-light">
                    Debe escoger a un arquitecto que se adapte a su presupuesto, pero nunca piense en ahorrar dinero eligiendo a un arquitecto económico o incluso que le regalará el proyecto si él lo construirá. Recuerde que el resultado final depende del arquitecto, de su capacidad, del tiempo e interés que le dedique personalmente a su proyecto, y eso cuesta dinero. El arquitecto ya le hará saber y sugerirá los conceptos en los que sí es conveniente ahorrar.
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    La manera en que los arquitectos cobran por sus servicios puede ser confusa para clientes que se acerquen a ellos por primera vez. No hay un honorario fijo por un tipo particular de proyecto. Los honorarios se establecen de varias maneras: depende un poco del proyecto y la cantidad y naturaleza de los servicios que satisfagan mejor sus necesidades.
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    Algunos proyectos se realizan mejor en tarifas por horas, otros por sumas estipuladas por unidad, basándose en cuanto es lo que se construye (por ejemplo, el número de metros cuadrados, cantidad de dapartamentos, habitaciones, etc.). Algunos arquitectos cobran honorarios fijos, otros cobran un porcentaje del costo total de la construcción. Cualquiera que crea que es el apropiado, discuta con su arquitecto como espera establecer los honorarios en su proyecto. El arquitecto podrá sugerir una combinación sobre los métodos. La base para los honorarios, la cantidad y el calendario de pagos son temas que usted y el arquitecto deberán resolver juntos.
                </h5>

                <h3 className="py-4 fw-semibold">Trabajo en equipo</h3>

                <h5 className="lh-base fw-light">
                    Los mejores proyectos de construcción son creados cuando el arquitecto y el cliente trabajan juntos como un equipo. Se le recomienda tomar un papel activo. No delegue decisiones a su pareja o compañeros de trabajo a menos que esté preparado para vivir con sus decisiones.
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    Diseñar un edificio es un emocionante reto creativo; el proceso puede ser divertido y positivo, pero es también un trabajo duro. Si en cualquier momento en el proceso de diseño se siente incómodo, discuta sus preocupaciones con su arquitecto. Usted no desea que el arquitecto controle el proyecto al punto de que ya no sea suyo. Pero debe también ser cuidadoso en no restringir demasiado al arquitecto para no interferir en términos de creatividad y calidad en el diseño.
                </h5>

                <h3 className="py-4 fw-semibold">Hágalo por escrito</h3>

                <h5 className="lh-base fw-light">
                    Una vez que ha encontrado al arquitecto, usted está listo para poner por escrito los términos de su acuerdo en el alcance del trabajo, servicios, calendario, presupuesto para construcción y compensación para el arquitecto. Este acuerdo por escrito puede tomar muchas formas.
                </h5>

                <h6 className="small fw-light mt-5 text-end">Copyright 1989. The American Institute of Architects.
                    <br />
                    Traducción y adaptación: Ámbito3, Arther y Alfonso Núñez-Arquitectos.
                </h6>

            </div>
        </>
    );
}

export default BlogTameplate;
