import "../../App.css";
import { Helmet } from 'react-helmet';
import PictureArquitecturaInventarDescubrirPrincipal from "../../Img/Blogs/ArquitecturaInventarDescubrir/61ce8847be32b1b5fbef299a_blog3.webp"


function ArquitecturaInventarDescubrir() {

    return (
        <>
            <Helmet>
                <title>Blog - Arquitectura: ¿Inventar O Descubrir?</title>
                <meta property="og:title" content="Guía para contratar servicios arquitectónicos" />
                <meta property="og:description" content="Aprende cómo contratar los mejores servicios arquitectónicos con nuestra guía completa. Descubre qué preguntar, cómo seleccionar un arquitecto y más. ¡Lee ahora!" />
                <meta property="og:url" content="https://demo-alfredo.godspeedcheckout.com" />
                <meta property="og:image" content="../../../public/Blogs/Guia-Arquitecto/arquitecto-sonriente-casco_23-2147702525.jpg" />
                <meta property="og:type" content="article" />
            </Helmet>

            <div className="container col-12 col-sm-12 col-md-12 col-lg-10 col-xl-7 col-xxl-8 py-4">
                <div className="col-12">
                    <div className="d-flex">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/blog">Blog</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Arquitectura: ¿Inventar O Descubrir?</li>
                            </ol>
                        </nav>
                    </div>
                    <h1 className="py-2 m-0 p-0">Arquitectura: ¿Inventar O Descubrir?</h1>
                    <h6 class="fw-light mt-3">10 de agosto, 2019</h6>
                </div>
            </div>

            <div className="container col-12 col-sm-12 col-md-12 col-lg-10 col-xl-7 col-xxl-8 mx-auto">
                <img src={PictureArquitecturaInventarDescubrirPrincipal} className="w-100 my-4" alt="Imagen Blog" />
            </div>

            <div className="container col-12 col-sm-12 col-md-12 col-lg-10 col-xl-7 col-xxl-5 mx-auto mt-4">
                <h5 className="lh-base fw-light">
                    La tarea del Arquitecto ¿Inventar nuevas formas y espacios novedosos y únicos, o descubrir cómo deben ser esos espacios…?
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    Los arquitectos investigamos básicamente tres elementos: el programa de necesidades, los espacios convenientes para resolverlos adecuadamente y las posibilidades materiales de construir esos espacios. La arquitectura – ese espacio delimitado por el hombre- nace de una necesidad física/material, psicológica/espiritual del hombre. Pero necesidades concretas de determinada persona -o conjunto de personas-: aquellos que la habitarán…
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    Hay una fuerte tendencia en la arquitectura por la búsqueda de crear construcciones novedosas, inéditas, únicas…, pero al enfocarse en estas características se corre el riesgo de descuidar la funcionalidad y adecuación de los espacios a las necesidades reales del proyecto. Para que la construcción sea en verdad Arquitectura, debe poseer un cuidadoso balance entre la “creación artística” y  “funcionalidad práctica”.
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    Tomemos por ejemplo “la casa”, “el  hogar”: la vida de una familia tiene muchas necesidades que pueden coincidir con las de otras familias, pero seguramente cada familia tendrá prioridades y maneras distintas de vivir espacios similares. La tarea del arquitecto será indagar las necesidades y requerimientos del futuro habitante para que el diseño se adecúe como un guante a la mano; normalmente investigará una infinidad de conceptos para conocer cuáles son estas necesidades espaciales y poder resolverlas adecuadamente:
                </h5>

                <h5 className="lh-base mt-4 fw-light">
                    Resulta obvio  que en la medida que el arquitecto investigue correctamente, tendrá más elementos para desarrollar el proyecto  resolviendo todas esas necesidades. El resultado no es tanto un esfuerzo de la imaginación, sino un proceso de investigación y descubrimiento: descubrimos cuantos espacios, cómo deben ser y como deben estar relacionados para que funcione como las requiere el futuro habitante. Desde luego que la intención artística del arquitecto le dará la conformación final, pero con la intención irrenunciable de resolver esas necesidades que generaron el proceso del diseño.
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    En la medida que ahondemos en nuestra investigación el proyecto posiblemente tomará forma por sí solo y las alternativas se irán reduciendo hasta el momento que posiblemente nos queden pocas opciones para trabajar: el verdadero arte de la arquitectura será  trabajar con estos resultados; no es en absoluto restricción a la creatividad: es su punto de partida. Posiblemente deberemos sacrificar algunas características que deseábamos para el proyecto, pero si realizamos este proceso con seriedad y sentido común, será difícil que incurramos en un error grave y arrojemos a sus nuevos habitantes a un purgatorio silencioso…
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    Recuerdo el proyecto de un arquitecto cercano que  por “capricho formal” –no conozco si de él o de su cliente-: en un terreno rectangular, todos los muros de la casa estaban girados 45 grados, generando innumerables espacios desperdiciados y harto difíciles de amueblar…  El resultado “estético” fue muy interesante, pero la vida diaria para los habitantes no creo que lo fuera tanto… El arquitecto podrá presumir su creación y la familia estar orgullosa de su nueva casa a pesar de no percatarse  que vivir ahí era como usar unos zapatos apretados sin comprender que pudieron haber sido hechos a la medida. Sin duda hubiera sido conveniente cuestionarse desde un principio: ¿Por qué girar la casa 45°? ¿Qué ventajas tendrá? ¿Cómo se utilizarán los espacios perdidos? ¿Cómo se amueblarán las habitaciones? ¿El resultado estético justifica los inconvenientes prácticos…?
                </h5>
                
                <h5 className="lh-base fw-light mt-4">
                    Diseñar novedosas y espectaculares formas y espacios -¡De revista…!- no es necesariamente complicado: ésto lo puede lograr cualquiera con buena imaginación o que tenga acceso a Instagram… Descubrir ese “deber ser” de la arquitectura –en mi experiencia- es mucho más difícil, pero nos abre las puertas hacia el camino de lograr una  arquitectura genuinamente bella, en su funcionalidad y su verdad. De la misma manera que el cuerpo humano es bello, en donde todos sus miembros, su tamaño y ubicación tienen una razón de ser: eso es belleza. Lo bello – también en la arquitectura- está íntimamente ligada a la comprensión equilibrada de la finalidad: del “cómo”, “por qué”, “para qué”…
                </h5>
               
                <h5 className="lh-base fw-light mt-4">
                    Que esté construido no significa necesariamente que sea arquitectura ni que esté bien construido; si está bien construida es una construcción bien hecha. Arquitectura es aquella que está bien construida, resuelve todas las necesidades y tiene una intención más allá de la resolución material: lograr esas emociones y sentimientos que buscamos al habitarla.
                </h5>
               
                <h6 className="fw-light mt-4">
                    — Arq. Alfonso Núñez.
                </h6>

            </div>
        </>
    );
}

export default ArquitecturaInventarDescubrir;
