import "../../../App.css"

import BTVThumbnails from "../../../Img/Proyectos/ProyectosTurismo/HotelEsencia/FB_IMG_1712680955993.jpg"
import HotelCasaCoculaThumbnails from "../../../Img/Proyectos/ProyectosTurismo/HotelCasaColula/61cd2e46ec15b50310e3fc1c_2.jpg"
import HotelNoPalaThumbnails from "../../../Img/Proyectos/ProyectosTurismo/HotelNoPala/WhatsApp Image 2019-03-04 at 7.17.15 AM(1).jpeg"
import ProyectoBalthazarThumbnails from "../../../Img/Proyectos/ProyectosTurismo/HotelBalthazar/01 Vista.jpg"
import ProyectoBuceriasThumbnails from "../../../Img/Proyectos/ProyectosTurismo/ProyectoBucerias/Vista 08.jpg"
import ProyectoElMarquezThumbnails from "../../../Img/Proyectos/ProyectosTurismo/ProyectoElMarquez/Vista 01.jpg"
import { Helmet } from "react-helmet"

function ProyectosTurismoHome() {
    return (
        <>

            <Helmet>
                <title>Hoteles</title>
            </Helmet>

            <div className="container col-12 py-5">
                <div className="d-flex">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/proyectos">Proyectos </a></li>
                            <li class="breadcrumb-item active" aria-current="page">Hoteles</li>
                        </ol>
                    </nav>
                </div>
                <h1 className="mt-0">Hoteles</h1>
            </div>

            <div class="container justify-content-center align-items-center">
                <div class="row gx-4 gy-4">

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-hotel-casa-cocula">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={HotelCasaCoculaThumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Hotel Casa Cocula</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-hotel-esencia">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={BTVThumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Hotel Esencia</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-hotel-nopala">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={HotelNoPalaThumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Hotel NoPala</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-balthazar">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={ProyectoBalthazarThumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Proyecto Balthazar</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-bucerias">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={ProyectoBuceriasThumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Proyecto Bucerias</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4 col-xl-4">
                        <a href="/proyecto-el-marquez">
                            <div class="card rounded-0 border-0 h-100">
                                <img src={ProyectoElMarquezThumbnails} class="card-img-top img-fluid h800 rounded-0" alt="Imagen de ejemplo" />
                                <div class="overlay">
                                    <h5 class="text-on-hover">Proyecto El Marquez</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                </div>
            </div>

        </>
    );
}

export default ProyectosTurismoHome;
