import "../App.css"
import fotoA from "../Img/alfonso.png"
import PlanoFoto from "../Img/plano3.svg"
import Foto from "../Img/equipo-vertice-estudio.png"
import { Helmet } from "react-helmet"

function Nosotros() {
    return (
        <>

            <Helmet>
                <title>Nosotros - Alfonso Nuñez Arquitectos</title>
            </Helmet>


            <section className="py-3">
                <div className="container col-12 col-sm-12 col-md-12 col-lg-10 col-xl-7 mx-auto text-center">
                    <div class="row row-cols-1 row-cols-md-2 align-items-center">



                        <div class="col">
                            <div class="card h-100 ">
                                <div class="card-body">
                                    <h6 className="text-uppercase small ls-2px fw-normal opacity-50 text-center">Estudio de Arquitectura</h6>
                                    <h1 className="fw-medium lh-sm text-center">Más de 30 años de experiencia</h1>
                                </div>
                            </div>
                        </div>

                        <div class="col">
                            <div class="card h-100">
                                <div class="card-body">
                                    <img src={Foto} className="img-fluid" alt="Foto de Alfonso" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container col-12 col-sm-12 col-md-12 col-lg-10 col-xl-7 mx-auto text-center">
                    <div class="row row-cols-1 row-cols-md-2">

                        <div class="col">
                            <div class="card h-100">
                                <div class="card-body">
                                    <h6 className="lh-base fw-light text-justify">Siempre es una gran satisfacción tener la oportunidad de asumir el reto que nos brindan nuestros clientes para colaborar en encontrar las soluciones adecuadas para sus sueños y necesidades.</h6>
                                    <h6 className="lh-base fw-light mt-3 text-justify">Nuestra preocupación es el trato y contacto personal con los futuros habitantes para conocerlos y enfocarnos en el análisis de los requerimientos para que la propuesta sea una solución a la medida y enriquecedora en todos sus aspectos dentro de su materialidad: funcional, espacial, sicológico.</h6>
                                    <h6 className="lh-base fw-light mt-3 text-justify">Nos interesa que nuestras propuestas sean esencialmente funcionales: que se adapten a la vida que albergaran y de alguna manera propongan una nueva forma de relación creativa y armoniosa entre sus ocupantes: que sea una arquitectura para vivirse, y que su vida nos enriquezca. Nuestra experiencia nos ha llevado especialmente a la arquitectura residencial, que consideramos la expresión más digna y comprometedora para la labor del arquitecto: la arquitectura se convierte en arte sagrado por su finalidad, especialmente en “la casa”: ese espacio que al habitarlo, nos transforma.</h6>
                                </div>
                            </div>
                        </div>

                        <div class="col">
                            <div class="card h-100">
                                <div class="card-body">
                                    <h6 className="lh-base fw-light text-justify">Nos involucramos en todo el proceso, desde la búsqueda del terreno y ubicación adecuada del terreno, hasta el proceso de solución arquitectónica en todos sus aspectos, así como el acompañamiento en el proceso constructivo, realizando la supervisión de las obras hasta los últimos detalles –estéticos y funcionales- trabajando de la mano con el contratista.</h6>
                                    <h6 className="lh-base fw-light mt-3 text-justify">Desde luego que nos hemos involucrado en todo tipo de proyectos, desde proyectos residenciales, naves industriales, hotelería y arquitectura comercial… Parte importante en nuestra experiencia es la arquitectura de playa –casas de descanso- que nos ha tocado realizar, en la que se conjuga el habitar con el arte de convivir con la naturaleza.</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}

export default Nosotros;
