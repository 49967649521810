import "../../App.css";
import { Helmet } from 'react-helmet';
import PictureAcabadosPrincipal from "../../Img/Blogs/ConsideracionesArqu/diaz_crop1521524732492.jpg_423682103.webp"
import PictureAcabados1 from "../../Img/Blogs/AcabadosDeLujo/61ce9e2fb9b6985bac8287f3_Web-1920-–-7.webp"
import PictureAcabados2 from "../../Img/Blogs/AcabadosDeLujo/61ce9f47d0a0a57a99ebc7dd_blog_12.webp"
import PictureAcabados3 from "../../Img/Blogs/ConsideracionesArqu/Captura de pantalla 2024-08-28 203255.png"


function ConsideracionesArqu() {




    return (
        <>
            <Helmet>
                <title>Blog - Consideraciones sobre arquitectura</title>
                <meta property="og:title" content="Guía para contratar servicios arquitectónicos" />
                <meta property="og:description" content="Aprende cómo contratar los mejores servicios arquitectónicos con nuestra guía completa. Descubre qué preguntar, cómo seleccionar un arquitecto y más. ¡Lee ahora!" />
                <meta property="og:url" content="https://demo-alfredo.godspeedcheckout.com" />
                <meta property="og:image" content="../../../public/Blogs/Guia-Arquitecto/arquitecto-sonriente-casco_23-2147702525.jpg" />
                <meta property="og:type" content="article" />
            </Helmet>

            <div className="container col-12 col-sm-12 col-md-12 col-lg-10 col-xl-7 col-xxl-8 py-4">
                <div className="col-12">
                    <div className="d-flex">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/blog">Blog</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Consideraciones sobre arquitectura</li>
                            </ol>
                        </nav>
                    </div>
                    <h1 className="py-2 m-0 p-0">Consideraciones sobre arquitectura</h1>
                    <h6 class="fw-light">11 de agosto, 2019</h6>
                </div>
            </div>

            <div className="container col-12 col-sm-12 col-md-12 col-lg-10 col-xl-7 col-xxl-4 mx-auto">
                <img src={PictureAcabadosPrincipal} className="w-100  my-4" alt="Imagen Blog" />
            </div>

            <div className="container col-12 col-sm-12 col-md-12 col-lg-10 col-xl-7 col-xxl-5 mx-auto mt-4">
                <h5 className="lh-base fw-light">
                    Hace días tuve la oportunidad de asistir en “Casa Clavijero” -obra emblemática de Luis Barragán- a un homenaje al Arq. Ignacio Díaz Morales, a 25 años de su muerte.
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    Díaz Morales ha tenido una gran trascendencia en la historia de la arquitectura tapatía por su intervención en proyectos como la Cruz de plazas, el Templo expiatorio, la capilla para las hermanas Mercedarias y la capilla del seminario menor, entre otros muchos proyectos... También ha pasado a la historia como en fundador de la primera escuela de arquitectura en Guadalajara, como teórico sobre arquitectura y maestro de muchas generaciones de arquitectos.
                </h5>

                <h5 className="lh-base fw-light mt-4 fw-light">
                    Me toco coincidir con él en contadas ocasiones; lo recuerdo especialmente en una exposición de acuarela que realicé hace ya muchos años -1989- y de quien recibí de buenas críticas a pesar de ser yo solo un principiante… Pero más allá de mis recuerdos, noté en este evento toda una generación de arquitectos – personas que le conocieron, colaboraron con él o fueron sus alumnos…- que le guardaban un gran cariño, admiración y respeto. Y quizás juzgo a la ligera, pero me pareció que más que admiración por su obra –que desde luego es de admirarse- lo que trascendía para los presentes fue su gran calidad como persona, su autoridad moral, la coherencia con sus convicciones y su profunda espiritualidad. Todo esto sin duda plasmado de alguna manera en su obra.
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    La arquitectura, como toda obra del hombre, refleja necesariamente para bien o para mal a su autor,  su ser,  su pensamiento, sus convicciones…: tema de reflexión para el quehacer arquitectónico; para todo arquitecto, para mi ejercicio como arquitecto… Revisar desde dónde nacen las ideas,  y soluciones a los retos que se nos presentan en cada nuevo proyecto; y más interesante…: ¿A dónde nos lleva en lo personal ese trabajo…?
                </h5>

                <h5 className="lh-base fw-light mt-4">
                    Me llamó la atención el tema de la Exposición: “La intimidad y lo sagrado en la arquitectura de Ignacio Díaz Morales”; tema de por sí sugerente para cualquier arquitecto; tema en el que mi mente ha divagado desde hace tiempo: ¿Hay arquitectura sagrada y profana, o toda la arquitectura -por servir al hombre- es de alguna manera “sagrada”? Posiblemente el hogar sea lo más sagrado para el hombre: ese lugar al cual regresa siempre, en donde teje sus sueños, crea y vive su familia, vive su amor…; ésto ya es tema para otra ocasión. Pero como bien nos trataba de inculcar un buen maestro en la universidad -también lejana en el tiempo…-: “La arquitectura es algo serio, y requiere soluciones serias”; ahora lo veo con mayor claridad.
                </h5>

                <img src={PictureAcabados3} className="img-fluid mt-4" alt="Imagen Blog" />

                <h5 className="lh-base fw-light mt-4">
                    Un momento de reflexión que me mueve a escribir y compartir estas líneas y -así lo espero- que me lleve también a compartir más reflexiones en el futuro…
                </h5>



                

                <h6 className="fw-light mt-4">
                    — Arq. Alfonso Núñez.<p>
                        3 de Agosto del 2018</p>
                </h6>

            </div>
        </>
    );
}

export default ConsideracionesArqu;
