import React, { useState } from "react";
import FsLightbox from "fslightbox-react";
import "../../../App.css"
import image1 from "../../../Img/Proyectos/ProyectosTurismo/HotelEsencia/61cd32fe8be532378a14b774_Hotel-Esencia-photos-Exterior (1).jpg"
import image2 from "../../../Img/Proyectos/ProyectosTurismo/HotelEsencia/61cd3143362646710e23b9e5_Esencia_139.jpg"
import image3 from "../../../Img/Proyectos/ProyectosTurismo/HotelEsencia/61cd31b7caa2663baeb5d370_Esencia_039.jpg"
import image4 from "../../../Img/Proyectos/ProyectosTurismo/HotelEsencia/61cd321f2ef0ae3094fbc186_Esencia_159.jpg"
import image5 from "../../../Img/Proyectos/ProyectosTurismo/HotelEsencia/61cd32902ef0ae4b09fbc2ab_Esencia_233.jpg"
import image6 from "../../../Img/Proyectos/ProyectosTurismo/HotelEsencia/61cd32cbec15b57b52e41050_Esencia_449cuadrada.jpg"
import image7 from "../../../Img/Proyectos/ProyectosTurismo/HotelEsencia/65d4cf23165cf9daaa953e65_Esencia_457.jpg"
import { Helmet } from "react-helmet";

function ProyectoHotelEsencia() {

    const [toggler, setToggler] = useState(false);

    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });

    function openLightboxOnSlide(number) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number
        });
    }


    return (
        <>
            <FsLightbox
                toggler={lightboxController.toggler}

                sources={[
                    image1, image2, image3, image4, image5, image6, image7
                ]}

                slide={lightboxController.slide}
            />

            <Helmet>
                <title>Hoteles - Hotel Esencia</title>
            </Helmet>

            <div className="container col-12 py-5">
                <div className="d-flex">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/proyectos-turismo">Hoteles</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Hotel Esencia</li>
                        </ol>
                    </nav>
                </div>
                <h1 className="py-2 m-0 p-0">Hotel Esencia</h1>
                <h6 className="py-2 m-0 p-0">Playa Xpu-Ha, QR.<h6>2004</h6></h6>
            </div>

            <div className="container position-relative">
                <img onClick={() => openLightboxOnSlide(1)} type="button" src={image1} className="w-100" alt="..." height={650} />
            </div>


            <div className="container">
                <div className="row gy-4 mt-1">

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(2)}
                            src={image2}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(3)}
                            src={image3}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(4)}
                            src={image4}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(5)}
                            src={image5}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(6)}
                            src={image6}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(7)}
                            src={image7}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                </div>
            </div>
        </>
    );
}

export default ProyectoHotelEsencia;
