import React, { useState } from "react";
import { Helmet } from "react-helmet";
import FsLightbox from "fslightbox-react";
import "../../../App.css"
import image1 from "../../../Img/Proyectos/ProyectosDesarrollo/DesarrolloManzanillo/C.jpg"
import image2 from "../../../Img/Proyectos/ProyectosDesarrollo/DesarrolloManzanillo/A.jpg"
import image3 from "../../../Img/Proyectos/ProyectosDesarrollo/DesarrolloManzanillo/B.jpg"
import image4 from "../../../Img/Proyectos/ProyectosDesarrollo/DesarrolloManzanillo/D.jpg"
import image5 from "../../../Img/Proyectos/ProyectosDesarrollo/DesarrolloManzanillo/E.jpg"
import image6 from "../../../Img/Proyectos/ProyectosDesarrollo/DesarrolloManzanillo/F.jpg"
import image7 from "../../../Img/Proyectos/ProyectosDesarrollo/DesarrolloManzanillo/G.jpg"
import image8 from "../../../Img/Proyectos/ProyectosDesarrollo/DesarrolloManzanillo/H.jpg"
import image9 from "../../../Img/Proyectos/ProyectosDesarrollo/DesarrolloManzanillo/I.jpg"
import image10 from "../../../Img/Proyectos/ProyectosDesarrollo/DesarrolloManzanillo/J.jpg"
import image11 from "../../../Img/Proyectos/ProyectosDesarrollo/DesarrolloManzanillo/K.jpg"


function ProyectoDesarrolloManzanillo() {

    const [toggler, setToggler] = useState(false);

    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });

    function openLightboxOnSlide(number) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number
        });
    }


    return (
        <>

            <Helmet>
                <title>Varios - Desarrollo Manzanillo</title>
            </Helmet>

            <FsLightbox
                toggler={lightboxController.toggler}

                sources={[
                    image1, image2, image3, image4, image5, image6, image7, image8, image9, image10, image11
                ]}

                slide={lightboxController.slide}
            />

            <div className="container col-12 py-5">
                <div className="d-flex">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/proyectos-en-desarrollo">Varios</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Desarrollo Manzanillo</li>
                        </ol>
                    </nav>
                </div>
                <h1 className="py-2 m-0 p-0">Desarrollo Manzanillo</h1>
                <h6 className="py-2 m-0 p-0">Playa Cuyutlán, Manzanillo Colima.<h6>2008</h6></h6>
            </div>

            <div className="container position-relative">
                <img onClick={() => openLightboxOnSlide(1)} type="button" src={image1} className="w-100" alt="..." height={650} />
            </div>


            <div className="container">
                <div className="row gy-4 mt-1">

                    <div className="col-lg-6 col-md-12">
                        <img onClick={() => openLightboxOnSlide(2)}
                            src={image2}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <img onClick={() => openLightboxOnSlide(3)}
                            src={image3}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <img onClick={() => openLightboxOnSlide(4)}
                            src={image4}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <img onClick={() => openLightboxOnSlide(5)}
                            src={image5}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <img onClick={() => openLightboxOnSlide(6)}
                            src={image6}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <img onClick={() => openLightboxOnSlide(7)}
                            src={image7}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <img onClick={() => openLightboxOnSlide(8)}
                            src={image8}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <img onClick={() => openLightboxOnSlide(9)}
                            src={image9}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <img onClick={() => openLightboxOnSlide(10)}
                            src={image10}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <img onClick={() => openLightboxOnSlide(11)}
                            src={image11}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                </div>
            </div>
        </>
    );
}

export default ProyectoDesarrolloManzanillo;
