import React, { useState } from "react";
import { Helmet } from "react-helmet";
import FsLightbox from "fslightbox-react";
import "../../../App.css"
import image1 from "../../../Img/Proyectos/ProyectosCasasDescanso/CasaSuenos/61ce668103a8ee9a98a9fb6c_SUEÑO 1.jpg"
import image2 from "../../../Img/Proyectos/ProyectosCasasDescanso/CasaSuenos/61ce667eb29eb091a2f06679_SUEÑO 4.jpg"
import image3 from "../../../Img/Proyectos/ProyectosCasasDescanso/CasaSuenos/61ce667fa77d7408db8758d3_SUEÑO 6.jpg"
import image4 from "../../../Img/Proyectos/ProyectosCasasDescanso/CasaSuenos/61ce6658a77d74ef848758bf_SUEÑO 7.jpg"
import image5 from "../../../Img/Proyectos/ProyectosCasasDescanso/CasaSuenos/61ce66805d1eb2335985cc8e_SUEÑO 9.jpg"
import image6 from "../../../Img/Proyectos/ProyectosCasasDescanso/CasaSuenos/61ce667ff9e0ca2e388b6e19_SUEÑO 10.jpg"
import image7 from "../../../Img/Proyectos/ProyectosCasasDescanso/CasaSuenos/61ce6680caa266c48dbd1626_SUEÑO 11.jpg"
import image8 from "../../../Img/Proyectos/ProyectosCasasDescanso/CasaSuenos/61ce667f5599ff76dd27b878_SUEÑO 12.jpg"


function ProyectoCasaSueno() {

    const [toggler, setToggler] = useState(false);

    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });

    function openLightboxOnSlide(number) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number
        });
    }


    return (
        <>

            <Helmet>
                <title>Proyecto Casas de Descanso - Casa Sueño, Manzanillo</title>
            </Helmet>

            <FsLightbox
                toggler={lightboxController.toggler}

                sources={[
                    image1, image2, image3, image4, image5, image6, image7, image8
                ]}

                slide={lightboxController.slide}
            />

            <div className="container col-12 col-sm-12 col-md-12 col-lg-10 col-xl-8 col-xxl-5 mx-auto pb-4">
                <h1 className="py-4 text-center m-0 p-0">Casa Sueño, Manzanillo</h1>
            </div>

            <div className="container position-relative">
                <img onClick={() => openLightboxOnSlide(1)} type="button" src={image1} className="w-100" alt="..." height={650} />
            </div>


            <div className="container">
                <div className="row gy-4 mt-1">

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(2)}
                            src={image2}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(3)}
                            src={image3}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(4)}
                            src={image4}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(5)}
                            src={image5}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    
                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(6)}
                            src={image6}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    
                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(7)}
                            src={image7}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <img onClick={() => openLightboxOnSlide(8)}
                            src={image8}
                            className="w-100 h-100"
                            alt="imagen proyecto"
                        />
                    </div>




                </div>
            </div>
        </>
    );
}

export default ProyectoCasaSueno;
