import "../App.css"
import ThumPD from "../Img/Proyectos/ProyectosDesarrollo/ThumPD.jpg"
import ThumPCD from "../Img/Proyectos/ProyectosCasasDescanso/ThumPCD.jpg"
import ThumPR from "../Img/Proyectos/ProyectosResidenciales/Palmas14/61ce062d8d36f1ba32b8fa15_1.jpg"
import ThumPT from "../Img/Proyectos/ProyectosTurismo/ThumPT.jpg"
import ThumIyC from "../Img/Proyectos/ProyectosIyC/ThumPIyC.jpg"
import { Helmet } from "react-helmet"

function Proyectos() {


  return (
    <>

      <Helmet>
        <title>Proyectos - Alfonso Nuñez Arquitectos</title>
      </Helmet>

      <section>
        <div className="container col-md-12">
          <h1 className="mb-4 lh-1 py-5">Proyectos</h1>

          <div class="row row-cols-1 row-cols-md-3 gy-4">

          <div class="col">
              <div class="card h-100">
                <a href="/proyectos-residenciales">
                  <img src={ThumPR} class="card-img-top h300" alt="Imagen de Residenciales" />
                  <div class="mt-2">
                    <h6 class="card-title text-uppercase ls-0_5px fw-bold">Residencial</h6>
                  </div>
                </a>
              </div>
            </div>

            <div class="col">
              <div class="card h-100">
                <a href="/proyectos-playa-y-montana">
                  <img src={ThumPCD} class="card-img-top h300" alt="Imagen Casas de Descanso" />
                  <div class="mt-2">
                    <h6 class="card-title text-uppercase ls-0_5px fw-bold">Playa y Montaña</h6>
                  </div>
                </a>
              </div>
            </div>

            <div class="col">
              <div class="card h-100">
                <a href="/proyectos-hoteles">
                  <img src={ThumPT} class="card-img-top h300" alt="Imagen de Turismo" />
                  <div class="mt-2">
                    <h6 class="card-title text-uppercase ls-0_5px fw-bold">Hoteles</h6>
                  </div>
                </a>
              </div>
            </div>

            <div class="col">
              <div class="card h-100">
                <a href="/proyectos-varios">
                  <img src={ThumIyC} class="card-img-top h300" alt="Imagen de Industrial y comercial" />
                  <div class="mt-2">
                    <h6 class="card-title text-uppercase ls-0_5px fw-bold">Comercial</h6>
                  </div>
                </a>
              </div>
            </div>

            <div class="col">
              <div class="card h-100">
                <a href="/proyectos-en-desarrollo">
                  <img src={ThumPD} class="card-img-top h300" alt="Imagen Desarrollos" />
                  <div class="mt-3">
                    <h6 class="card-title text-uppercase ls-0_5px fw-bold">Varios</h6>
                  </div>
                </a>
              </div>
            </div>

          </div>

        </div>
      </section >

    </>
  );
}

export default Proyectos;
